import axios from 'axios';

const API_URL = 'https://api-ustadium.com';

export const loginAPI = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/auth/token`, credentials);
    return response.data.data;
  } 
  catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const signupAPI = async (userInfo) => {
  try {
    const response = await axios.post(`${API_URL}/auth/signup`, userInfo);
    return response.data.data;
  }
  catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const feedAPI = async (token, userID, page) => {
  try {
    const response = await axios.get(
      `${API_URL}/api/feed/subscribed?userID=${userID}&limit=20&page=${page}&sort=newest&order=DESC`, {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  } 
  catch (error) {
    throw new Error('Failed to get posts.');
  }
};

export const takesAPI = async (token, userId, page, filter) => {
  try {
    const requestBody = {
      "userID": userId,
      "page": page,
      "filter": filter,
      "forAuthor": null
    };
    const response = await axios.post(
      `${API_URL}/api/list/takes`,
      requestBody,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  } 
  catch (error) {
    throw new Error('Failed to get takes.');
  }
};

export const leaderboardAPI = async (token, userId, page) => {
  try {
    const date = new Date();
    const [month, year] = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
    }).split("/");

    const requestBody = {
      "userID": userId,
      "page": page,
      "month": `${year}-${month}`,
      "type": "global" 
    };
    const response = await axios.post(
      `${API_URL}/api/leaderboard/global`,
      requestBody, 
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  } 
  catch (error) {
    throw new Error('Failed to get leaderboard.');
  }
};

export const notificationsAPI = async (token, userId, page) => {
  try {
    const requestBody = {
      "limit": 20,
      "page": page,
      "type": "all",
      "userID": userId
    };
    const response = await axios.post(
      `${API_URL}/api/notifications/get`,
      requestBody, 
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  } 
  catch (error) {
    throw new Error('Failed to get notifications.');
  }
};

export const indivTakeAPI = async (takeId, userId) => {
  try {
    const response = await axios.get(`${API_URL}/api/take/${takeId}/${userId}`);
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to get take.');
  };
};

export const indivPostAPI = async (postId, token) => {
  try {
    const response = await axios.get(
      `${API_URL}/api/posts/${postId}`,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to get post.');
  };
};

export const commentsAPI = async (postId) => {
  const requestBody = {
    "postID": postId,
    "batchSize": 500,
    "offset": 0
  };
  try {
    const response = await axios.post(
      `${API_URL}/api/comment/getPost`,
      requestBody
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to get comments.');
  };
};

export const commentRepliesAPI = async (token, userId, commentId) => {
  const requestBody = {
    "userID": userId,
    "commentID": commentId,
    "batchSize": 500,
    "offset": 0
  };
  try {
    const response = await axios.post(
      `${API_URL}/api/comment/replies`,
      requestBody, 
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to get comment replies.');
  };
};

export const createPost = async (token, post) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/posts`, post, {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data;
  }
  catch (error) {
    throw new Error('Failed to create post.');
  }
};

export const createTake = async (token, take) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/takes`, take, {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data;
  }
  catch (error) {
    throw new Error('Failed to create take.');
  }
};

export const getUserPoints = async (userID) => {
  try {
    const response = await axios.get(`${API_URL}/api/users/${userID}/points`);
    if (response.data.error) {
      throw new Error();
    }
    return response.data;
  }
  catch (error) {
    throw new Error('Failed to get user points.');
  }
};

export const participateTake = async (takeId, requestBody, token) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/participate/${takeId}`, requestBody, {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data;
  }
  catch (error) {
    throw new Error('Failed to participate in take.');
  }
};

export const getUserProfile = async (userToRequest, userId, token) => {
  try {
    const response = await axios.get(
      `${API_URL}/api/profile/${userToRequest}/${userId}`, {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to get user profile.');
  }
};

export const getUserPosts = async (userToRequest, pageNumber, token) => {
  try {
    const requestBody = {
      userID: userToRequest,
      page: pageNumber
    };
    const response = await axios.post(
      `${API_URL}/api/users/posts`, requestBody, {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to get user posts.');
  }
};

export const getUserTakes = async (token, userId, page, forAuthor) => {
  try {
    const requestBody = {
      "userID": userId,
      "page": page,
      "filter": {
          "takes_settled": false,
          "participated": null,
          "restrict": false,
          "category": 1
      },
      "forAuthor": forAuthor
    };
    const response = await axios.post(
      `${API_URL}/api/list/takes`,
      requestBody,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  } 
  catch (error) {
    throw new Error('Failed to get user takes.');
  }
};

export const followUserAPI = async (userId, userToFollow, token) => {
  try {
    const requestBody = {
      userID: userId,
      userToFollow: userToFollow
    };
    const response = await axios.post(
      `${API_URL}/api/follow`, 
      requestBody,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data;
  }
  catch (error) {
    throw new Error('Failed to follow user');
  }
};

export const unfollowUserAPI = async (userId, userToUnfollow, token) => {
  try {
    const requestBody = {
      userID: userId,
      userToUnfollow: userToUnfollow
    };
    const response = await axios.post(
      `${API_URL}/api/unfollow`, 
      requestBody,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data;
  }
  catch (error) {
    throw new Error('Failed to unfollow user');
  }
};

export const getFollowersList = async (userId, pageNumber, userToRequest, token) => {
  try {
    const requestBody = {
      userID: userToRequest,
      page: pageNumber,
      requestingUser: userId
    };
    const response = await axios.post(
      `${API_URL}/api/followers`, 
      requestBody,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.followers;
  }
  catch(error) {
    throw new Error('Failed to get followers.');
  }
};

export const getFollowingList = async (userId, pageNumber, userToRequest, token) => {
  try {
    const requestBody = {
      userID: userToRequest,
      page: pageNumber,
      requestingUser: userId
    };
    const response = await axios.post(
      `${API_URL}/api/following`, 
      requestBody,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.following;
  }
  catch(error) {
    throw new Error('Failed to get following list.');
  }
};

export const likePost = async (postId, token) => {
  try {
    const response = await axios.put(
      `${API_URL}/api/posts/${postId}/react/1`,
      null,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to like post');
  }
};

export const unlikePost = async (postId, token) => {
  try {
    const response = await axios.delete(
      `${API_URL}/api/posts/${postId}/react`,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to unlike post');
  }
};

export const likeComment = async (commentId, token) => {
  try {
    const response = await axios.put(
      `${API_URL}/api/comment/${commentId}/react/1`,
      null,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to like comment');
  }
};

export const unlikeComment = async (commentId, token) => {
  try {
    const response = await axios.delete(
      `${API_URL}/api/comment/${commentId}/react`,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to unlike post');
  }
};

export const createComment = async (postId, requestBody, token) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/posts/${postId}/reply`,
      requestBody,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data.data;
  }
  catch (error) {
    throw new Error('Failed to create comment');
  }
};

export const suggestedUsersApi = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/api/users/suggested/${userId}`);
    if (response.data.error) {
      throw new Error();
    }
    return response.data.results;
  }
  catch (error) {
    throw new Error('Failed to create comment');
  }
};

export const createPaymentIntent = async (paymentDetails) => {
  try {
    const response = await axios.post(`https://us-central1-ustadium-new.cloudfunctions.net/app/payment`, paymentDetails);
    if (response.data.error) {
      throw new Error();
    }
    return response.data;
  }
  catch (error) {
    throw new Error ('Failed to create payment intent');
  }
};

export const addPurchasePoints = async (id, points, token) => {
  try {
    const requestBody = {
      id,
      points,
      message: `You've purchased ${points} points!`,
      pointsType: 'PurchasePoints'
    };
    const response = await axios.post(
      `${API_URL}/api/gift/points`,
      requestBody,
      {
        headers: {
          Authorization: `JWT ${token}`
        }
      }
    );
    if (response.data.error) {
      throw new Error();
    }
    return response.data;
  }
  catch (error) {
    throw new Error ('Failed to add purchase points'); 
  }
};


