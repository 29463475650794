import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getTimeDiff } from '../../utils/timeUtils';
import Linkify from 'linkify-react';
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import './styles.scss';
import { ReactComponent as FilledHeartIcon } from '../../assets/svg/filledheartIcon.svg';
import { ReactComponent as HeartIcon } from '../../assets/svg/heartIcon.svg';
import { ReactComponent as CommentIcon } from '../../assets/svg/commentIcon.svg';
import { useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import CreateComment from '../createComment';
import { likeComment, unlikeComment } from '../../services/apiService';
import { modalStyles } from '../../utils/miscUtils';
import toast from 'react-hot-toast';

const Comment = (props) => {
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const { isAuthenticated, token } = useSelector(state => state.auth);
  const [isLiked, setIsLiked] = useState(false);
  const [numLikes, setNumLikes] = useState(props.comment.positive);
  const { postId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLiked(!!props.comment.reactedWith);
    }
  }, [isAuthenticated, props.comment.reactedWith]);
  
  const handleOpenCommentModal = () => {
    if (isAuthenticated) {
      setIsCommentModalOpen(true);
    } 
    else {
      navigate('/login');
    }
  };

  const handleToggleLike = async () => {
    if (isAuthenticated) {
      setIsLiked(!isLiked)

      if (isLiked) {
        try {
          setNumLikes(numLikes - 1);
          await unlikeComment(props.comment.id, token);
        }
        catch (error) {
          toast.error('Failed to unlike', {
            position: 'top-right'
          });
          console.error(error);
        }
      }
      else {
        try {
          setNumLikes(numLikes + 1);
          await likeComment(props.comment.id, token);
        }
        catch (error) {
          toast.error('Failed to like', {
            position: 'top-right'
          });
          console.error(error);
        }
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="comment-container">
      <div className="user-avatar-container">
        <img src={props.comment.author.media.length ? props.comment.author.media[0].link : defaultAvatar} width="40" height="40" alt="avatar"></img>
        <Link to={`/user/${props.comment.author.id}`} className="link-to-profile">
          <p>@{props.comment.author.username}</p>
        </Link>
        <p>&nbsp;·&nbsp;{getTimeDiff(props.comment.createdAt)} </p>
      </div>
      <Linkify as="p" className="comment-text-fix">{props.comment.text}</Linkify>
      {props.comment.media.length ? <img src={props.comment.media[0].link} alt="comment img" width="50%"></img> : null}
      <div className='comment-user-actions-container'>
        <p>
          { isLiked ? <FilledHeartIcon className="svg-icon" onClick={handleToggleLike}/> : <HeartIcon className="svg-icon" onClick={handleToggleLike}/> }
          {numLikes}
        </p>
        <p>
          <CommentIcon className='svg-icon' onClick={handleOpenCommentModal}/>
        </p>
      </div>
      <hr></hr>
      <ReactModal
        isOpen={isCommentModalOpen}
        onRequestClose={() => setIsCommentModalOpen(false)}
        className='comment-modal'
        overlayClassName='comment-modal-overlay'
        style={modalStyles}
      >
        <CreateComment
          userReplyingTo={props.comment.author.username}
          idReplyingTo={props.comment.id}
          textReplyingTo={props.comment.text}
          postId={postId}
          replyToType='comment'
          onClose={() => setIsCommentModalOpen(false)}
          showReplyToDetails={true}
        />
      </ReactModal>
    </div>
  );
};

export default Comment;