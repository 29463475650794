import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { suggestedUsersApi } from '../services/apiService';

const initialState = {
  suggestedUsersList: [],
  status: null,
  error: null
};

export const getSuggestedUsers = createAsyncThunk('suggestedUsers/getSuggestedUsers', async (_, { getState }) => {
  const userId = getState().auth.user.id;
  const suggestedUsers = await suggestedUsersApi(userId);
  return suggestedUsers;
});

const suggestedUsersSlice = createSlice({
  name: 'suggestedUsers',
  initialState,
  reducers: {
    clearSuggestedUsers: (state) => {
      state.suggestedUsersList = [];
      state.status = null;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getSuggestedUsers.fulfilled, (state, action) => {
      state.suggestedUsersList = action.payload;
      state.status = 'succeeded';
      state.error = null;
    })
    .addCase(getSuggestedUsers.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    })
    .addCase(getSuggestedUsers.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  }
});

export const { clearSuggestedUsers } = suggestedUsersSlice.actions;
export default suggestedUsersSlice.reducer;
