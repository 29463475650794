import React, { useEffect, useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux";
import { clearUserFollowing, getFollowingThunk } from "../../reducers/userFollowingSlice";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';

const UserFollowing = (props) => {
  const { isAuthenticated } = useSelector(state => state.auth);
  const { following, status, error, hasMoreData } = useSelector(state => state.userFollowing);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getFollowingThunk({userToRequest: props.userToRequest, pageNumber: 0}));
    }
    return (() => {
      dispatch(clearUserFollowing());
    });
  }, [isAuthenticated, props.userToRequest]);

  const scrollRef = useBottomScrollListener(() => {
    if (hasMoreData && status === 'succeeded') {
      dispatch(getFollowingThunk({userToRequest: props.userToRequest, pageNumber: pageNumber}));
      setPageNumber(pageNumber + 1);
    }
  });

  return (
    <div className="user-following-container" ref={scrollRef}>
      <CloseIcon className="close-icon" onClick={props.onClose}/>
      {following.length ? following.map(followingUser => {
        return (
          followingUser ?
          <div className="user-item-container" key={followingUser.id}>
            <img src={followingUser.media.length ? followingUser.media[0].link : defaultAvatar} alt="avatar"></img>
            <Link to={`/user/${followingUser.id}`} className="link-to-profile">
              <p>@{followingUser.username}</p>
            </Link>
          </div> : null
        );
      }) : null}
      {status === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
    </div>
  );
};

export default UserFollowing;