import React, { useEffect } from "react";

const Shop = () => {
  useEffect(() => {
    window.location.href = 'https://ustadium-store.printify.me/products';
  }, []);

  return (
    <div></div>
  );
};

export default Shop;