import React, { useEffect, useState } from "react";
import './styles.scss';
import TakeFeed from "../takeFeed";
import { useDispatch, useSelector } from "react-redux";
import { getUserTakesThunk, clearUserTakes } from "../../reducers/userTakesSlice";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useNavigate } from "react-router-dom";

const UserTakes = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.auth);
  const {takes, status, error, hasMoreData} = useSelector(state => state.userTakes);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserTakesThunk({userToRequest: props.userToRequest, pageNumber: 0}));
    }
    return (() => {
      dispatch(clearUserTakes());
    });
  }, [isAuthenticated, props.userToRequest]);

  useBottomScrollListener(() => {
    if (hasMoreData && status === 'succeeded') {
      dispatch(getUserTakesThunk({userToRequest: props.userToRequest, pageNumber: pageNumber}));
      setPageNumber(pageNumber + 1);
    }
  });

  return (
    <div>
      {takes.map(take => {
        return (take && <TakeFeed 
          key={take.id} 
          take={take}
          navigateTake={() => {
            navigate(`/takes/${take.id}`);
          }}   
        />);
      })}
      {status === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
    </div>
  );
}

export default UserTakes;