import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearTakes, getTakes, incrementPage, resetPage } from "../../reducers/takesSlice";
import TakeFeed from "../takeFeed";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import './styles.scss';
import { useNavigate } from "react-router-dom";
import { ReactComponent as AllIcon } from '../../assets/svg/allIcon.svg';
import { ReactComponent as NFLIcon } from '../../assets/svg/footballIcon.svg';
import { ReactComponent as MLBIcon } from '../../assets/svg/baseballIcon.svg';
import { ReactComponent as FantasyIcon } from '../../assets/svg/fantasyIcon.svg';
import { ReactComponent as NBAIcon } from '../../assets/svg/basketballIcon.svg';
import { ReactComponent as SoccerIcon } from '../../assets/svg/soccerIcon.svg';
import { ReactComponent as HockeyIcon } from '../../assets/svg/hockeyIcon.svg';
import { ReactComponent as FightingIcon } from '../../assets/svg/fightingIcon.svg';
import { ReactComponent as OtherIcon } from '../../assets/svg/otherIcon.svg';
import { ReactComponent as PromoIcon } from '../../assets/svg/promoIcon.svg';

const TakesTab = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(state => state.auth);
  const { takesList, status, error, hasMoreData, nextPage } = useSelector(state => state.takes);
  const [selectedCategory, setSelectedCategory] = useState(1);

  const takesFilters = [
    { id: 1, label: 'ALL', icon: AllIcon },
    { id: 762, label: 'NFL', icon: NFLIcon },
    { id: 856, label: 'MLB', icon: MLBIcon },
    { id: 536, label: 'FANTASY', icon: FantasyIcon },
    { id: 825, label: 'NBA', icon: NBAIcon },
    { id: 889, label: 'SOCCER', icon: SoccerIcon },
    { id: 887, label: 'HOCKEY', icon: HockeyIcon },
    { id: 890, label: 'FIGHTING', icon: FightingIcon },
    { id: 2, label: 'PROMO', icon: PromoIcon },
    { id: 902, label: 'OTHER', icon: OtherIcon }
  ];

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getTakes({ 
        page: 0, 
        filter: {
          ...props.filter,
          category: selectedCategory
        } 
      }));
      dispatch(resetPage());
    }
    return (() => {
      dispatch(clearTakes());
    });
  }, [isAuthenticated, props.filter, selectedCategory]);

  useBottomScrollListener(() => {
    if (hasMoreData && status === 'succeeded') {
      dispatch(getTakes({ 
        page: nextPage, 
        filter: {
          ...props.filter,
          category: selectedCategory
        } 
      }));
      dispatch(incrementPage());
    }
  });

  const handleFilterTakes = (id) => {
    setSelectedCategory(id);
  };

  return (
    <div>
      <div className="takes-filters-container">
        {takesFilters.map(category => (
          <button
            key={category.id}
            className={`category-button ${selectedCategory === category.id ? 'selected' : ''}`}
            onClick={() => handleFilterTakes(category.id)}
          >
            <category.icon className="svg-icon"/>
            <span>{category.label}</span>
          </button>
        ))}
      </div>

      {takesList.map((take) => {
        return (take && <TakeFeed 
          key={take.id} 
          take={take}
          navigateTake={() => {
            navigate(`/takes/${take.id}`);
          }}  
        />);
      })}
      {status === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
    </div>
  );
};

export default TakesTab;