import React, { useState } from "react";
import './styles.scss';
import { truncateText } from "../../utils/miscUtils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { createComment } from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';

const CreateComment = (props) => {
  const [commentText, setCommentText] = useState(props.replyToType === 'comment' ? `@${props.userReplyingTo} ` : '');
  const { user, token } = useSelector(state => state.auth);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setCommentText(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requestBody = {
      text: commentText,
      authorObjectType: 'user',
      authorObject_id: user.id,
      media: [],
      replyToID: props.idReplyingTo,
      replyTo: props.replyToType
    }

    try {
      await createComment(props.postId, requestBody, token);

      navigate(`/p/${props.postId}`);
      window.location.reload();
    }
    catch (error) {
      toast.error('Failed to create comment', {
        position: 'top-right'
      });
      console.error(error);
    }
  };

  return (
    <div className="create-comment-container">
      {props.showReplyToDetails && <CloseIcon className="close-icon" onClick={props.onClose}/>}
      {props.showReplyToDetails && 
        <div className="reply-to-details">
          <h4>Replying to @{props.userReplyingTo}</h4>
          {props.textReplyingTo && 
            <div>
              <p>{truncateText(props.textReplyingTo)}</p>
            </div>
          }
        </div>
      }
      <form className="create-comment-form" onSubmit={handleSubmit}>
        <textarea 
          required
          placeholder="Write a comment..."
          value={commentText}
          onChange={handleChange}
        >
        </textarea>
        <button type="submit">Reply</button>
      </form>
    </div>
  );
};

export default CreateComment;