import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TakeFeed from "../takeFeed";
import { clearIndivTake, getIndivTake } from "../../reducers/indivTakeSlice";
import './styles.scss';
import LoginHeader from "../loginHeader";
import { ReactComponent as BackArrow } from "../../assets/svg/backArrowIcon.svg";
import ReactGA from 'react-ga4';

const IndivTake = () => {
  const { takeId } = useParams();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector(state => state.auth);
  const { take, status, error } = useSelector(state => state.indivTake);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getIndivTake({takeId: takeId, userId: user.id}));
    }
    else {
      dispatch(getIndivTake({takeId: takeId, userId: 0}));
    }

    ReactGA.event({
      category: 'everytime take is clicked and singular take appears',
      action: 'rich_take_impression_web'
    });

    return (() => {
      dispatch(clearIndivTake());
    });
  }, [isAuthenticated]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="indiv-take-container">
      {!isAuthenticated ? 
        <LoginHeader takeId={takeId}/>
        : null}
      <div className="back-button">
        <BackArrow className="back-arrow" onClick={handleGoBack}/>
      </div>
      {status === 'succeeded' && take ? 
        <TakeFeed
          take={take}
        />
        : null
      }
      {status === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
    </div>
  );
}

export default IndivTake;