import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../reducers/authSlice';
import feedReducer from '../reducers/feedSlice';
import takesReducer from '../reducers/takesSlice';
import notificationsReducer from '../reducers/notificationsSlice';
import leaderboardReducer from '../reducers/leaderboardSlice';
import indivTakeSlice from '../reducers/indivTakeSlice';
import userPostsSlice from '../reducers/userPostsSlice';
import userTakesSlice from '../reducers/userTakesSlice';
import userFollowersSlice from '../reducers/userFollowersSlice';
import userFollowingSlice from '../reducers/userFollowingSlice';
import indivPostSlice from '../reducers/indivPostSlice';
import indivPostCommentsSlice from '../reducers/indivPostCommentsSlice';
import suggestedUsersSlice from '../reducers/suggestedUsersSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  feed: feedReducer,
  takes: takesReducer,
  notifications: notificationsReducer,
  leaderboard: leaderboardReducer,
  indivPost: indivPostSlice,
  indivPostComments: indivPostCommentsSlice,
  indivTake: indivTakeSlice,
  userPosts: userPostsSlice,
  userTakes: userTakesSlice,
  userFollowers: userFollowersSlice,
  userFollowing: userFollowingSlice,
  suggestedUsers: suggestedUsersSlice
  // Add other reducers here
});

export default rootReducer;