import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { commentRepliesAPI } from "../../services/apiService";
import CommentReply from "../commentReply";

const CommentReplies = (props) => {
  const [replies, setReplies] = useState([]);
  const { isAuthenticated, token, user } = useSelector(state => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      commentRepliesAPI(token, user.id, props.commentId)
        .then(response => {
          setReplies(response);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [isAuthenticated]);

  return (
    <div className="comment-replies-container">
      {replies.map(reply => {
        return (
          <CommentReply
            key={reply.id}
            reply={reply}
            parentCommentId={props.commentId}
          />
        );
      })}
    </div>
  );
};

export default CommentReplies;