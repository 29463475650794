import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFollowingList } from "../services/apiService";
import { cleanData } from "../utils/miscUtils";

const initialState = {
  following: [],
  status: null,
  error: null,
  hasMoreData: true
};

export const getFollowingThunk = createAsyncThunk('userFollowing/getFollowing', async (payload, { getState }) => {
  const userId = getState().auth.user.id;
  const token = getState().auth.token;
  const userFollowing = await getFollowingList(userId, payload.pageNumber, payload.userToRequest, token);

  return userFollowing;
});

const userFollowingSlice = createSlice({
  name: 'userFollowing',
  initialState: initialState,
  reducers : {
    clearUserFollowing: (state) => {
      state.following = [];
      state.status = null;
      state.error = null;
      state.hasMoreData = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFollowingThunk.fulfilled, (state, action) => {
        if (!action.payload.length) {
          state.hasMoreData = false;
        }
        else {
          state.following = cleanData([...state.following, ...action.payload]);
        }        
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getFollowingThunk.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getFollowingThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { clearUserFollowing } = userFollowingSlice.actions;
export default userFollowingSlice.reducer;