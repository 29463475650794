import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './styles.scss';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import TakesTab from "../takesTab";
import { useSelector } from "react-redux";

const Takes = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(state => state.auth);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated]);

  return (
    <div className="takes-container">
      <h3>Takes</h3>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => {setTabIndex(index)}}>
        <TabList className="takes-tab">
          <Tab className="tab">Live</Tab>
          <Tab className="tab">Joined</Tab>
          <Tab className="tab">Settled</Tab>
        </TabList>

        <TabPanel>
          <TakesTab
            filter={{
              takes_settled: false,
              participated: null,
              restrict: false,
              category: 1
            }}
          />
        </TabPanel>
        <TabPanel>
          <TakesTab
            filter={{
              takes_settled: false,
              participated: true,
              restrict: null,
              category: 1
            }}
          />
        </TabPanel>
        <TabPanel>
          <TakesTab
            filter={{
              takes_settled: true,
              participated: true,
              restrict: null,
              category: 1
            }}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default Takes;