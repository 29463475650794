import React, { useEffect, useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux";
import { clearUserFollowers, getFollowersThunk } from "../../reducers/userFollowersSlice";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';


const UserFollowers = (props) => {
  const { isAuthenticated } = useSelector(state => state.auth);
  const { followers, status, error, hasMoreData } = useSelector(state => state.userFollowers);
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getFollowersThunk({userToRequest: props.userToRequest, pageNumber: 0}));
    }
    return (() => {
      dispatch(clearUserFollowers());
    });
  }, [isAuthenticated, props.userToRequest]);

  const scrollRef = useBottomScrollListener(() => {
    if (hasMoreData && status === 'succeeded') {
      dispatch(getFollowersThunk({userToRequest: props.userToRequest, pageNumber: pageNumber}));
      setPageNumber(pageNumber + 1);
    }
  });

  return (
    <div className="user-followers-container" ref={scrollRef}>
      <CloseIcon className="close-icon" onClick={props.onClose}/>
      {followers.length ? followers.map(follower => {
        return (
          follower ?
          <div className="user-item-container" key={follower.id}>
            <img src={follower.media.length ? follower.media[0].link : defaultAvatar} alt="avatar"></img>
            <Link to={`/user/${follower.id}`} className="link-to-profile">
              <p>@{follower.username}</p>
            </Link>
          </div> : null
        );
      }) : null}
      {status === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
    </div>
  );
};

export default UserFollowers;