import React, { useEffect, useState } from "react";
import './styles.scss';
import PostFeed from "../postFeed";
import { useDispatch, useSelector } from "react-redux";
import { clearUserPosts, getUserPostsThunk } from "../../reducers/userPostsSlice";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useNavigate } from "react-router-dom";

const UserPosts = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.auth);
  const {posts, status, error, hasMoreData} = useSelector(state => state.userPosts);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserPostsThunk({userToRequest: props.userToRequest, pageNumber: 0}))
    }
    return (() => {
      dispatch(clearUserPosts());
    });
  }, [isAuthenticated, props.userToRequest]);

  useBottomScrollListener(() => {
    if (hasMoreData && status === 'succeeded') {
      dispatch(getUserPostsThunk({userToRequest: props.userToRequest, pageNumber: pageNumber}));
      setPageNumber(pageNumber + 1);
    }
  });

  return (
    <div className="user-posts-container">
      {posts.map(post => (post && 
        <PostFeed 
          key={post.id} 
          post={post}
          navigatePost={() => {
            navigate(`/p/${post.id}`);
          }}  
        />
      ))}
      {status === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
    </div>
  );
}

export default UserPosts;