import React, { useEffect, useState } from "react";
import './styles.scss';
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import { Link, useParams } from "react-router-dom";
import { getTimeDiff } from "../../utils/timeUtils";
import { ReactComponent as FilledHeartIcon } from '../../assets/svg/filledheartIcon.svg';
import { ReactComponent as HeartIcon } from '../../assets/svg/heartIcon.svg';
import { ReactComponent as CommentIcon } from '../../assets/svg/commentIcon.svg';
import Linkify from "linkify-react";
import ReactModal from "react-modal";
import CreateComment from "../createComment";
import { useSelector } from "react-redux";
import { likeComment, unlikeComment } from "../../services/apiService";
import { modalStyles } from "../../utils/miscUtils";
import toast from "react-hot-toast";

const CommentReply = (props) => {
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const { isAuthenticated, token } = useSelector(state => state.auth);
  const [isLiked, setIsLiked] = useState(false);
  const [numLikes, setNumLikes] = useState(props.reply.positive);
  const { postId } = useParams();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLiked(!!props.reply.reactedWith);
    }
  }, [isAuthenticated, props.reply.reactedWith]);

  const handleToggleLike = async () => {
    if (isAuthenticated) {
      setIsLiked(!isLiked)

      if (isLiked) {
        try {
          setNumLikes(numLikes - 1);
          await unlikeComment(props.reply.id, token);
        }
        catch (error) {
          toast.error('Failed to unlike', {
            position: 'top-right'
          });
          console.error(error);
        }
      }
      else {
        try {
          setNumLikes(numLikes + 1);
          await likeComment(props.reply.id, token);
        }
        catch (error) {
          toast.error('Failed to like', {
            position: 'top-right'
          });
          console.error(error);
        }
      }
    }
  };
  
  const handleOpenCommentModal = () => {
    if (isAuthenticated) {
      setIsCommentModalOpen(true);
    }
  };

  return (
    <div className="comment-reply-container">
      <div className="user-avatar-container">
        <img src={props.reply.author.media.length ? props.reply.author.media[0].link : defaultAvatar} width="40" height="40" alt="avatar"></img>
        <Link to={`/user/${props.reply.author.id}`} className="link-to-profile">
          <p>@{props.reply.author.username}</p>
        </Link>
        <p>&nbsp;·&nbsp;{getTimeDiff(props.reply.createdAt)}</p>
      </div>
      <Linkify as="p" className="comment-text">{props.reply.text}</Linkify>
      <div className='comment-user-actions-container'>
        <p>
          { isLiked ? <FilledHeartIcon className="svg-icon" onClick={handleToggleLike}/> : <HeartIcon className="svg-icon" onClick={handleToggleLike}/> }
          {numLikes}
        </p>
        <p>
          <CommentIcon className='svg-icon' onClick={handleOpenCommentModal}/>
        </p>
      </div>
      <hr></hr>
      <ReactModal
        isOpen={isCommentModalOpen}
        onRequestClose={() => setIsCommentModalOpen(false)}
        className='comment-modal'
        overlayClassName='comment-modal-overlay'
        style={modalStyles}
      >
        <CreateComment
          userReplyingTo={props.reply.author.username}
          idReplyingTo={props.parentCommentId}
          textReplyingTo={props.reply.text}
          postId={postId}
          replyToType='comment'
          onClose={() => setIsCommentModalOpen(false)}
          showReplyToDetails={true}
        />
      </ReactModal>
    </div>
  );
};

export default CommentReply;