import React, { useCallback, useEffect, useState } from "react";
import './styles.scss';
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import { Link } from "react-router-dom";
import Linkify from "linkify-react";
import { ReactComponent as FilledHeartIcon } from '../../assets/svg/filledheartIcon.svg';
import { ReactComponent as HeartIcon } from '../../assets/svg/heartIcon.svg';
import { ReactComponent as CommentIcon } from '../../assets/svg/commentIcon.svg';
import { ReactComponent as ShareIcon } from '../../assets/svg/shareIcon.svg';
import {getTimeDiff, getFormattedDate} from '../../utils/timeUtils';
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import CreateComment from "../createComment";
import { likePost, unlikePost } from "../../services/apiService";
import { getImageGalleryData, modalStyles } from "../../utils/miscUtils";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import ReactGA from "react-ga4";
import Masonry from "react-responsive-masonry";
import ImageGallery from "react-image-gallery";
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';

const PostFeed = (props) => {
  const [isLiked, setIsLiked] = useState(false);
  const [numLikes, setNumLikes] = useState(props.post.score);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { isAuthenticated, token } = useSelector(state => state.auth);
  const copyUrl = `${window.location.origin}/p/${props.post.id}`;

  useEffect(() => {
    if (isAuthenticated) {
      setIsLiked(!!props.post.reactedWith);
    }

    ReactGA.event({
      category: 'everytime post appears in feed',
      action: 'post_cell_impression_web'
    });
  }, [isAuthenticated, props.post.reactedWith]);

  const onCopy = useCallback(() => {
    toast.success('Copied to clipboard', {
      position: 'top-right'
    });
  }, []);

  const isTextSelected = () => {
    const selection = window.getSelection();
    return selection && selection.toString().length > 0;
  }

  const handleToggleLike = async () => {
    if (isAuthenticated) {
      setIsLiked(!isLiked);

      if (isLiked) {
        try {
          setNumLikes(numLikes - 1);
          await unlikePost(props.post.id, token);
        }
        catch (error) {
          toast.error('Failed to unlike', {
            position: 'top-right'
          });
          console.error(error);
        }
      }
      else {
        try {
          setNumLikes(numLikes + 1);
          await likePost(props.post.id, token);
        }
        catch (error) {
          toast.error('Failed to like', {
            position: 'top-right'
          });
          console.error(error);
        }
      }
    }
  };

  const handleCommentModal = (bool) => {
    if (isAuthenticated) {
      setIsCommentModalOpen(bool);
    }
  };

  const handleNavigatePost = () => {
    if (!isTextSelected()) {
      props.navigatePost && props.navigatePost();
    }
  };

  const openImageModal = (imageIndex) => {
    setSelectedImageIndex(imageIndex);
    setIsImageModalOpen(true);
    document.body.classList.add('image-modal-open');
  }

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    document.body.classList.remove('image-modal-open');
  }

  return (
    <div className="postfeed-container">
      <div className="user-avatar-container">
        <img src={props.post.author.media.length ? props.post.author.media[0].link : defaultAvatar} width="40" height="40" alt="avatar"></img>
        <Link to={`/user/${props.post.author.id}`} className="link-to-profile">
          <p className="username">@{props.post.author.username}</p>
        </Link>
        <p> · </p>
        <Link to={`/p/${props.post.id}`} className="link-to-post">
          <span title={getFormattedDate(props.post.createdAt)}>
            <p>{getTimeDiff(props.post.createdAt)}</p>
          </span>
        </Link>
      </div>
      <div className="post-content-container" onClick={handleNavigatePost} style={props.navigatePost ? {cursor: 'pointer'} : null}>
        <Linkify as="p" className="post-text">
          {props.post.title.split(/@(\w+)/g).map((segment, index) => {
            if (index % 2 === 1) {
              if (props.post.taggedUsers && segment in props.post.taggedUsers) {
                return <Link key={index} to={`/user/${props.post.taggedUsers[segment].id}`}>{`@${segment}`}</Link>
              }
              return <span key={index}>{`@${segment}`}</span>;
            } 
            return <span className="text-of-post" key={index}>{segment}</span>;
          })}
        </Linkify>
        <div className="images-container">
          {props.post.hasPhotos ?
            <Masonry columnsCount={2}>
              {props.post.media.map((img, index) => {
                return (
                  <img 
                    className="post-image" 
                    key={index} 
                    src={img.link} 
                    alt="graphic" 
                    onClick={(e) => {
                      e.stopPropagation();
                      openImageModal(index);
                    }}>
                  </img> 
                );
              })}
            </Masonry>
          : null}
        </div>
        <div className="videos-container">
          {props.post.hasVideos ?
            props.post.media.map((video, index) => {
              return (
                <video key={index} width="50%" controls>
                  <source src={video.link} type="video/mp4" />
                </video>
              ); 
            }) 
          : null}
        </div>
        {props.post.text ?
          <div className="blog-text-container">
            <p>{props.post.text}</p>
          </div>
          : null
        }
      </div>
      <hr style={{ width: '98%', margin: 'auto', border: '1px solid #3A4052' }} />
      <div className="post-details-container">
          <p>
            { isLiked ? <FilledHeartIcon className="svg-icon" onClick={handleToggleLike}/> : <HeartIcon className="svg-icon" onClick={handleToggleLike}/> }
            {numLikes}
          </p>
          <p>
            <CommentIcon className="svg-icon" onClick={() => {handleCommentModal(true)}}/>
            {props.post.numComments}
          </p>
          <p>
            <CopyToClipboard onCopy={onCopy} text={copyUrl}>
              <ShareIcon className="svg-icon"/>
            </CopyToClipboard>
          </p>
      </div>
      <ReactModal
        isOpen={isCommentModalOpen}
        onRequestClose={() => handleCommentModal(false)}
        className='comment-modal'
        overlayClassName='comment-modal-overlay'
        style={modalStyles}
      >
        <CreateComment
          userReplyingTo={props.post.author.username}
          idReplyingTo={props.post.id}
          textReplyingTo={props.post.title}
          postId={props.post.id}
          replyToType='post'
          onClose={() => setIsCommentModalOpen(false)}
          showReplyToDetails={true}
        />
      </ReactModal>
      <ReactModal
        isOpen={isImageModalOpen}
        onRequestClose={closeImageModal}
        className='image-modal'
        overlayClassName='image-modal-overlay'
        style={modalStyles}
      >
        <CloseIcon className="close-icon" onClick={closeImageModal}/>
        <ImageGallery
          items={getImageGalleryData(props.post.media)}
          startIndex={selectedImageIndex}
          showPlayButton={false}
          showFullscreenButton={false}
        />
      </ReactModal>
    </div>
  );
}

export default PostFeed;