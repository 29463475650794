import React from "react";
import './styles.scss';
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import { Link } from "react-router-dom";

const IndivSuggestedUser = (props) => {
  return (
    <div className="indiv-suggested-user-container">
      <div className="suggested-user-avatar-container">
        <img src={props.suggestedUser.media.length ?  props.suggestedUser.media[0].link : defaultAvatar} alt="avatar" width="40" height="40"/>
        <Link to={`/user/${props.suggestedUser.id}`} className="link-to-profile">
          <p>@{props.suggestedUser ? props.suggestedUser.username : null}</p>
        </Link>
      </div>
      <button onClick={() => props.followSuggestedUser(props.suggestedUser.id)}>Follow</button>
    </div>
  );
};

export default IndivSuggestedUser;