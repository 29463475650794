import React, { useEffect, useState } from "react";
import './styles.scss';
import { createTake, getUserPoints } from "../../services/apiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';
import { useNavigate } from "react-router-dom";

const CreateTake = (props) => {
  const [newTake, setNewTake] = useState({
    takeContent: '',
    points: '',
    category: '',
    isPromo: false
  });

  const [userPoints, setUserPoints] = useState({
    cashoutPoints: '',
    promoPoints: ''
  });

  const [takeDate, setTakeDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    fetchPoints()
      .then(response => {
        setUserPoints({
          cashoutPoints: Math.floor(response.cashoutPoints + response.availablePromoPurchase),
          promoPoints: Math.floor(response.availablePromoPoints)
        });
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewTake({
      ...newTake,
      [name]: name === 'category' || name === 'points' ? parseInt(value) : value
    });
  }

  const handleCheckboxChange = (event) => {
    setNewTake((prevState) => ({
      ...prevState,
      isPromo: !prevState.isPromo
    }));
  }

  const fetchPoints = async () => {
    const response = await getUserPoints(props.userId);
    return response;
  }

  const validateTake = () => {
    const currentTime = new Date();

    if (takeDate < currentTime) {
      toast.error('Take expiration must be later than the current time', {
        position: 'top-right'
      });
      return false;
    }

    if (newTake.isPromo) {
      if (userPoints.promoPoints < newTake.points) {
        toast.error('Not enough promo points', {
          position: 'top-right'
        });
        return false;
      }
    } 
    else {
      if (userPoints.cashoutPoints < newTake.points) {
        toast.error('Not enough points', {
          position: 'top-right'
        });
        return false;
      }
    }
    return true;
  }

  const handleSubmitTake = async (event) => {
    event.preventDefault();

    if (validateTake()) {
      const currentTime = new Date();
      const newTakeRequestBody = {
        userid: props.userId,
        thetake: newTake.takeContent,
        takespoints: newTake.points,
        publishedon: currentTime.toISOString(),
        elapsedon: takeDate.toISOString(),
        category: newTake.category,
        ispromopoints: newTake.isPromo
      };
      try {
        await createTake(props.token, newTakeRequestBody);
        setNewTake({
          takeContent: '',
          points: '',
          category: '',
          isPromo: false
        });

        navigate('/takes');
        window.location.reload();
      }
      catch (error) {
        toast.error('Failed to create take', {
          position: 'top-right'
        });
        console.error(error);
      }
    }
  }

  return (
    <div className="create-take-container">
      <CloseIcon className="close-icon" onClick={props.onClose}/>
      <h2>Give us a 🔥 take</h2>
      <hr></hr>
      <div className="user-avatar-container">
        <img className="user-avatar" src={props.user ? (props.user.media.length ? props.user.media[0].link : defaultAvatar) : null} alt="user avatar"></img>
        <h3 className="user-name">{props.user ? props.user.username : null}</h3>
      </div>
      <form className="create-take-form" onSubmit={handleSubmitTake}>
        <textarea
          className="create-take-textarea"
          name="takeContent"
          placeholder="What's your take?"
          value={newTake.takeContent}
          onChange={handleChange}
          required
          autoFocus
        />
        <div className="create-take-input-container">
          <div className="take-input-left">
            <select name="category" value={newTake.category} onChange={handleChange} required>
              <option value="" disabled>Choose a category</option>
              <option value={762}>🏈Football</option>
              <option value={825}>🏀Basketball</option>
              <option value={856}>⚾️Baseball</option>
              <option value={536}>🦄Fantasy</option>
              <option value={889}>⚽️Soccer</option>
              <option value={887}>🏒Hockey</option>
              <option value={890}>🥊Fighting</option>
              <option value={902}>🔮Other</option>
            </select>
            <DatePicker
              className="date-picker"
              selected={takeDate}
              onChange={(date) => setTakeDate(date)}
              minDate={new Date()}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              required
            />
          </div>
          <div className="take-input-right">
            <h4>Points: {userPoints.cashoutPoints}</h4>
            <h4>Promo Points: {userPoints.promoPoints}</h4>
            <input 
              className="points-amount-input-container"
              type="number"
              name="points"
              placeholder="Enter amount"
              value={newTake.points}
              onChange={handleChange}
              min="1"
              required
            />
            <label className="promo-point-checkbox">
              <input
                type="checkbox"
                checked={newTake.isPromo}
                onChange={handleCheckboxChange}
              />
              Use Promo Points
            </label>
          </div>
        </div>
        <button type="submit" className="submit-take-button">
          Confirm Take
        </button>
      </form>
    </div>
  );
}

export default CreateTake;