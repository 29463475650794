import React, { useEffect } from "react";
import './styles.scss';
import IndivSuggestedUser from "../indivSuggestedUser";
import { useDispatch, useSelector } from "react-redux";
import { clearSuggestedUsers, getSuggestedUsers } from "../../reducers/suggestedUsersSlice";
import { followUserAPI } from "../../services/apiService";
import toast from "react-hot-toast";

const SuggestedUsers = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user, token } = useSelector(state => state.auth);
  const { suggestedUsersList, status, error } = useSelector(state => state.suggestedUsers);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSuggestedUsers());
    }

    return () => {
      dispatch(clearSuggestedUsers());
    };
  }, [isAuthenticated]);

  const followSuggestedUser = async (userToFollow) => {
    followUserAPI(user.id, userToFollow, token)
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        toast.error('Failed to follow user', {
          position: 'top-right'
        });
        console.error(error);
      });
  };

  return (
    isAuthenticated ?
    <div className="suggested-users-container">
      <h2>Suggested Users</h2>
      <div className="suggested-users-list">
        {suggestedUsersList.map(suggestedUser => {
          return (suggestedUser && suggestedUser.id !== user.id ?
            <IndivSuggestedUser 
              key={suggestedUser.id}
              suggestedUser={suggestedUser} 
              followSuggestedUser={followSuggestedUser}
            /> : null
          );
        })}
      </div>
      {status === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
    </div>
    : null
  );
};

export default SuggestedUsers;