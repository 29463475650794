import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PostFeed from "../postFeed";
import './styles.scss';
import LoginHeader from "../loginHeader";
import CommentReplies from "../commentReplies";
import Comment from "../comment";
import { clearIndivPost, getIndivPost } from "../../reducers/indivPostSlice";
import { clearIndivPostComments, getComments } from "../../reducers/indivPostCommentsSlice";
import { ReactComponent as BackArrow } from "../../assets/svg/backArrowIcon.svg";
import ReactGA from 'react-ga4';
import CreateComment from "../createComment";

const IndivPost = () => {
  const { postId } = useParams();
  const { isAuthenticated } = useSelector(state => state.auth);
  const { post: indivPost, status: indivPostStatus, error: indivPostError } = useSelector(state => state.indivPost);
  const { comments: postComments, status: postCommentsStatus, error: postCommentsError } = useSelector(state => state.indivPostComments);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getIndivPost(postId));
    dispatch(getComments(postId));
    
    window.scrollTo(0, 0);

    ReactGA.event({
      category: 'everytime post is clicked and singular post appears',
      action: 'rich_post_impression_web'
    });

    return (() => {
      dispatch(clearIndivPost());
      dispatch(clearIndivPostComments());
    });
  }, [isAuthenticated, postId]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="indiv-post-container">
      {!isAuthenticated ? 
        <LoginHeader postId={postId} />
      : null}
      <div className="back-button">
        <BackArrow className="back-arrow" onClick={handleGoBack}/>
      </div>
      {indivPost ?
        <div className="post-and-comments-container">
          <PostFeed post={indivPost} />
          <div className="horizontal-bar-top">
            <hr style={{ width: '94%', margin: 'auto', border: '1px solid #3A4052'}} />
          </div>
          { 
            <div className="comments-container">
              {postComments && postComments.length ? 
                postComments.map((comment) => {
                  return (
                    <div key={comment.id} className="comment-and-replies-container">
                      <Comment
                        comment={comment}
                      />
                      <CommentReplies
                        commentId={comment.id}
                      />
                    </div>
                  );
                })
                : null
              }
              {postCommentsStatus === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
              {postCommentsError && <p>{postCommentsError}</p>}
            </div> 
          }
          {isAuthenticated &&
            <CreateComment
              userReplyingTo={indivPost.author.username}
              idReplyingTo={indivPost.id}
              textReplyingTo={indivPost.title}
              postId={indivPost.id}
              replyToType='post'
              showReplyToDetails={false}
            />
          }
          <div className="bottom-space"></div>
        </div> 
        : null
      }
      {indivPostStatus === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {indivPostError && <p>{indivPostError}</p>}
    </div>
  );
}

export default IndivPost;