import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { indivPostAPI } from '../services/apiService';

const initialState = {
  post: null,
  status: null,
  error: null
};

export const getIndivPost = createAsyncThunk('indivPost/getIndivPost', async (postId, { getState }) => {
  const token = getState().auth.token;
  const post = await indivPostAPI(postId, token);
  return post;
});

const indivPostSlice = createSlice({
  name: 'indivPost',
  initialState,
  reducers: {
    clearIndivPost: (state) => {
      state.post = null;
      state.status = null;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIndivPost.fulfilled, (state, action) => {        
        state.post = action.payload;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getIndivPost.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getIndivPost.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { clearIndivPost } = indivPostSlice.actions;
export default indivPostSlice.reducer;

