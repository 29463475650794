import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { addPurchasePoints, createPaymentIntent } from "../../services/apiService";
import toast from "react-hot-toast";
import './styles.scss';
import Popup from "reactjs-popup";

const BuyPointsCheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentValue, setPaymentValue] = useState(null);
  const [customValue, setCustomValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);
  const paymentValueOptions = [5, 10, 25, 50, 100, 200];
  const [customerName, setCustomerName] = useState({
    firstName: '',
    lastName: ''
  });
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: 'white',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
    },
  };

  const openConfirmPopup = () => {
    if (validatePaymentValue()) {
      setConfirmPopupOpen(true);
    }
  };

  const closeConfirmPopup = () => {
    setConfirmPopupOpen(false);
  };

  const clearFormData = () => {
    setPaymentValue(null);
    setCustomValue('');
    setCustomerName({
      firstName: '',
      lastName: ''
    });
    elements.getElement(CardElement).clear();
  };

  const validatePaymentValue = () => {
    if (!paymentValue) {
      toast.error('Please select or enter a payment value', {
        position: 'top-right'
      });
      clearFormData();
      return false;
    }
    else if (paymentValue <= 0 || paymentValue > 200) {
      toast.error('Payment value must be between $1-$200', {
        position: 'top-right'
      });
      clearFormData();
      return false;
    }
    return true;
  }

  const handleConfirmPayment = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      closeConfirmPopup();

      if (!stripe || !elements) {
        return;
      }

      if (!(customerName.firstName && customerName.lastName)) {
        throw new Error('Please enter your first and last name');
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${customerName.firstName} ${customerName.lastName}`
        }
      });

      if (error) {
        throw new Error(error.message);
      } 
      else {
        const paymentDetails = {
          amount: parseInt(paymentValue) * 100,
          paymentMethod: paymentMethod
        };
        const response = await createPaymentIntent(paymentDetails);
        const clientSecret = response.paymentIntent.client_secret;
        const { error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: `${customerName.firstName} ${customerName.lastName}`
            }
          },
        });
        
        if (error) {
          throw new Error('There was an error processing your payment');
        } 
        else {
          const response = await addPurchasePoints(props.userId, parseInt(paymentValue) * 1000, props.token);

          if (response.success) {
            setLoading(false);

            toast.success('Payment successful!', {
              position: 'top-right'
            });
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } 
          else {
            throw new Error('There was an error adding points to your account');
          }
        }
      }
    }
    catch (error) {
      toast.error(error.message, {
        position: 'top-right'
      });
      console.error(error);
      setLoading(false);
      clearFormData();
    }
  };

  const handleOptionSelect = (option) => {
    setPaymentValue(option);
    setCustomValue('');
  };

  const handleInputChange = (event) => {
    setPaymentValue(event.target.value);
    setCustomValue(event.target.value);
  };

  const handleNameInputChange = (event) => {
    const { name, value } = event.target;
    setCustomerName(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="buy-points-form-container">
      <form className="buy-points-form" onSubmit={handleConfirmPayment}>
        <div className="buy-points-options-container">
          {paymentValueOptions.map((option, index) => (
            <button
              type="button"
              key={index}
              onClick={() => handleOptionSelect(option)}
              className={paymentValue === option ? 'selected' : null}
            >
              ${option}
            </button>
          ))}
        </div>
        <label htmlFor="custom-value">Custom Value ($)</label>
        <input
          type="number"
          className="custom-value-input-container no-spinner"
          name="custom-value"
          value={customValue}
          onChange={handleInputChange}
          onClick={() => setPaymentValue(null)}
        />
        <div className="name-input-container">
          <input 
            type="text"
            className="custom-value-input-container"
            name="firstName"
            value={customerName.firstName}
            onChange={handleNameInputChange}
            placeholder="First Name"
            required
          />
          <input 
            type="text"
            className="custom-value-input-container"
            name="lastName"
            value={customerName.lastName}
            onChange={handleNameInputChange}
            placeholder="Last Name"
            required
          />
        </div>
        <CardElement options={cardElementOptions} className="card-element-container"/>
        <button type="button" className="submit-pay-button" onClick={openConfirmPopup}>Purchase Points</button>
        {confirmPopupOpen && <Popup
          modal
          open={confirmPopupOpen}
          closeOnDocumentClick
          onClose={closeConfirmPopup}
          className="confirm-payment-popup"
        >
          <div className="confirm-payment-popup-content-inner">
            <h2>Points you're purchasing: {(paymentValue * 1000).toLocaleString()}</h2>
            <div className="confirm-popup-buttons-container">
              <button onClick={handleConfirmPayment} className="confirm-payment-button" disabled={loading}>Confirm Payment</button>
              <button onClick={closeConfirmPopup} className="cancel-button">Cancel</button>
            </div>
          </div>
        </Popup>}
      </form>
      {loading && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
    </div>
  );
};

export default BuyPointsCheckoutForm;