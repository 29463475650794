import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notificationsAPI } from '../services/apiService';
import { cleanData } from '../utils/miscUtils';

const initialState = {
  notificationsList: [],
  nextPage: 1,
  status: null,
  error: null,
  hasMoreData: true
};

export const getNotifications = createAsyncThunk('notifications/getNotifications', async (page, { getState }) => {
  const token = getState().auth.token;
  const userId = getState().auth.user.id;
  const notifications = await notificationsAPI(token, userId, page);
  return notifications;
});

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotifications: (state) => {
      state.notificationsList = [];
      state.nextPage = 1;
      state.status = null;
      state.error = null;
      state.hasMoreData = true;
    },
    resetPage: (state) => {
      state.nextPage = 1;
    },
    incrementPage: (state) => {
      state.nextPage += 1;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getNotifications.fulfilled, (state, action) => {
      if (!action.payload.length) {
        state.hasMoreData = false;
      }
      else {
        state.notificationsList = cleanData([...state.notificationsList, ...action.payload]);
      }
      state.status = 'succeeded';
      state.error = null;
    })
    .addCase(getNotifications.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    })
    .addCase(getNotifications.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  }
});

export const { clearNotifications, resetPage, incrementPage } = notificationsSlice.actions;
export default notificationsSlice.reducer;


