import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { leaderboardAPI } from '../services/apiService';
import { cleanData } from "../utils/miscUtils";

const initialState = {
  leaderboardList: [],
  nextPage: 1,
  status: null,
  error: null,
  hasMoreData: true
};

export const getLeaderboard = createAsyncThunk('leaderboard/getLeaderboard', async (page, { getState }) => {
  const token = getState().auth.token;
  const userId = getState().auth.user.id;
  const leaderboard = await leaderboardAPI(token, userId, page);
  return leaderboard;
});

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    clearLeaderboard: (state) => {
      state.leaderboardList = [];
      state.nextPage = 1;
      state.status = null;
      state.error = null;
      state.hasMoreData = true;
    },
    resetPage: (state) => {
      state.nextPage = 1;
    },
    incrementPage: (state) => {
      state.nextPage += 1;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getLeaderboard.fulfilled, (state, action) => {
      if (!action.payload.length) {
        state.hasMoreData = false;
      }
      else {
        state.leaderboardList = cleanData([...state.leaderboardList, ...action.payload]);
      }
      state.status = 'succeeded';
      state.error = null;
    })
    .addCase(getLeaderboard.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    })
    .addCase(getLeaderboard.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
  }
});

export const { clearLeaderboard, resetPage, incrementPage } = leaderboardSlice.actions;
export default leaderboardSlice.reducer;