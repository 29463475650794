import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { commentsAPI } from '../services/apiService';

const initialState = {
  comments: [],
  status: null,
  error: null
};

export const getComments = createAsyncThunk('indivPostComments/getComments', async (postId) => {
  const comments = await commentsAPI(postId);
  return comments;
});

const indivPostCommentsSlice = createSlice({
  name: 'indivPostComments',
  initialState: initialState,
  reducers: {
    clearIndivPostComments: (state) => {
      state.comments = [];
      state.status = null;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComments.fulfilled, (state, action) => {        
        state.comments = action.payload;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getComments.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getComments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { clearIndivPostComments } = indivPostCommentsSlice.actions;
export default indivPostCommentsSlice.reducer;