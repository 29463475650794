import React, { useCallback, useEffect, useState } from "react";
import TakeFeed from "../takeFeed";
import './styles.scss';
import { getUserPoints, participateTake } from "../../services/apiService";
import toast from "react-hot-toast";
import ReactModal from 'react-modal';
import { modalStyles } from "../../utils/miscUtils";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';

const EnterTake = (props) => {
    const [userPoints, setUserPoints] = useState({
      cashoutPoints: '',
      promoPoints: ''
    });

    const [isPromoPoints, setIsPromoPoints] = useState(false);

    const handleToggle = () => {
        setIsPromoPoints(!isPromoPoints);
    };

    useEffect(() => {
      fetchPoints()
        .then(response => {
          setUserPoints({
            cashoutPoints: Math.floor(response.cashoutPoints + response.availablePromoPurchase),
            promoPoints: Math.floor(response.availablePromoPoints)
          });
        })
    }, []);

    const fetchPoints = async () => {
      const response = await getUserPoints(props.userId);
      return response;
    }
    
    const handleNavigateTake = () => {
      props.navigateTake && props.navigateTake();
    };

    const navigate = useNavigate();
    const { isAuthenticated, user, token } = useSelector(state => state.auth);
    const [isAgreeModalOpen, setIsAgreeModalOpen] = useState(false);
    const [isDisagreeModalOpen, setIsDisagreeModalOpen] = useState(false);

    const closeModal = () => {
      setIsAgreeModalOpen(false);
      setIsDisagreeModalOpen(false);
    };

    const openAgreeModal = (e) => {
        e.stopPropagation();
    
        if (!isAuthenticated) {
          navigate('/login');
        } 
        else {
          setIsAgreeModalOpen(true);
        }
      };
    
      const openDisagreeModal = (e) => {
        e.stopPropagation();
    
        if (!isAuthenticated) {
          navigate('/login');
        } 
        else {
          setIsDisagreeModalOpen(true);
        }
      };

      const [isEnterButtonVisible, setIsEnterButtonVisible] = useState(false);

      let takesStatus = 0;

      let agreePayoutPoints = ((props.disagreedTotal)*props.spendPoints*0.9)/(props.agreedTotal+1);
      let disagreePayoutPoints = ((props.agreedTotal)*props.spendPoints*0.9)/(props.disagreedTotal+1);

      const [isAgree, setIsAgree] = useState(false);
      const [isDisagree, setIsDisagree] = useState(false);

      const handleAgreeClick = () => {
        takesStatus = 1;
        setIsEnterButtonVisible(true);
        setIsDisagree(false);
        setIsAgree(true);
      }

      const handleDisagreeClick = () => {
        takesStatus = 0;
        setIsEnterButtonVisible(true);
        setIsAgree(false);
        setIsDisagree(true);
        
      }

      const handleSubmit = async (event) => {
        try {
          event.preventDefault();
          const requestBody = {
            userid: props.userId,
            takesstatus: takesStatus,
            spendpoints: props.spendPoints,
            takesauthorid: props.authorId,
          };
          await participateTake(props.takeId, requestBody, props.token);
          window.location.reload();
        }
        catch (error) {
          toast.error('Failed to join take', {
            position: 'top-right'
          });
          console.error(error);
        }
      }
  
    return (
        <>
          <div className='available-points'>Available: {userPoints.cashoutPoints.toLocaleString()}</div>
          <div>
            <div className='take-container'>
                <div className='the-take'>{props.the_take} </div>
                <div className='take-points'>Points you're risking: {props.spendPoints.toLocaleString()}</div>
                <button className={`buttons ${isAgree ? 'clicked' : ''}`} onClick={handleAgreeClick}>Agree</button>
                <button className={`buttons ${isDisagree ? 'clicked' : ''}`} onClick={handleDisagreeClick}>Disagree</button>
                <div className='payout-points'>
                    <div className='agree-disagree-payout'>{agreePayoutPoints}</div>
                    <div className='agree-disagree-payout'>{disagreePayoutPoints}</div>
                </div>
                <div className='payout-points'>
                    <div className='current-payout'>Current Payout</div>
                    <div className='current-payout'>Current Payout</div>
                </div>
            </div>
            <div className='promo-points'>Available Promo Points</div>
            <div className='use-promo'>
                Use {userPoints.promoPoints} promo points
                <div className={`toggle-container ${isPromoPoints ? 'on' : 'off'}`} onClick={handleToggle}>
                    <div className='toggle-switch' />
                </div>
            </div>
            {isEnterButtonVisible && (
                <form onSubmit={handleSubmit}>
                    <button type="submit">Confirm Entry</button>
                </form>
            )}
          </div>
        </>
    );
}
export default EnterTake;