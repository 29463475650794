import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loginAPI, signupAPI } from '../services/apiService';
import secureLocalStorage from 'react-secure-storage';

const initialState = {
  isAuthenticated: !!secureLocalStorage.getItem('ustadiumAuthToken'),
  token: secureLocalStorage.getItem('ustadiumAuthToken'),
  user: secureLocalStorage.getItem('ustadiumUser'),
  error: null
};

export const performLogin = createAsyncThunk('auth/performLogin', async (credentials) => {
  const loginResponse = await loginAPI(credentials);

  secureLocalStorage.setItem('ustadiumAuthToken', loginResponse.token);
  secureLocalStorage.setItem('ustadiumUser', loginResponse.user);

  return loginResponse;
});

export const performSignup = createAsyncThunk('auth/performSignup', async (userInfo) => {
  const signupResponse = await signupAPI(userInfo);

  secureLocalStorage.setItem('ustadiumAuthToken', signupResponse.token);
  secureLocalStorage.setItem('ustadiumUser', signupResponse.user);

  return signupResponse;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    performLogout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
      state.error = null;
      secureLocalStorage.removeItem('ustadiumAuthToken');
      secureLocalStorage.removeItem('ustadiumUser');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(performLogin.fulfilled, (state, action) => {
        state.isAuthenticated = !!action.payload.token;
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.error = null;
      })
      .addCase(performLogin.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.token = null;
        state.user = null;
        state.error = action.error.message;
      })
      .addCase(performSignup.fulfilled, (state, action) => {
        state.isAuthenticated = !!action.payload.token;
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.error = null;
      })
      .addCase(performSignup.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.token = null;
        state.user = null;
        state.error = action.error.message;
      });
  }
});

export const { performLogout } = authSlice.actions;
export default authSlice.reducer;
