import React, { useCallback, useEffect, useState } from "react";
import './styles.scss';
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import { Link, useNavigate } from "react-router-dom";
import {getTimeDiff, getFormattedDate} from '../../utils/timeUtils';
import { ReactComponent as TakeAgreeIcon } from '../../assets/svg/takeAgreeIcon.svg';
import { ReactComponent as TakeDisagreeIcon } from '../../assets/svg/takeDisagreeIcon.svg';
import { ReactComponent as ShareIcon } from '../../assets/svg/shareIcon.svg';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import { ReactComponent as PayoutIcon } from "../../assets/svg/coinsIcon.svg";
import { ReactComponent as PeopleIcon } from "../../assets/svg/peopleIcon.svg";
import { ReactComponent as SendIcon } from "../../assets/svg/sendIcon.svg";
import { ReactComponent as CountdownIcon} from "../../assets/svg/countdown.svg";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactGA from 'react-ga4';
import { useSelector } from "react-redux";
import ReactModal from 'react-modal';
import ConfirmParticipate from "../confirmParticipate";
import ParticipateTake from "../participateTake";
import EnterTake from "../enterTake";
import { modalStyles } from "../../utils/miscUtils";

const TakeFeed = (props) => {
  const agreedTotal = props.take.agreed_users ? props.take.agreed_users.length : 0;
  const disagreedTotal = props.take.disagreed_users ? props.take.disagreed_users.length : 0;
  const totalUsersInTake = agreedTotal + disagreedTotal;
  const copyUrl = `${window.location.origin}/takes/${props.take.id}`;
  const { isAuthenticated, user, token } = useSelector(state => state.auth);
  const [isAgreeModalOpen, setIsAgreeModalOpen] = useState(false);
  const [isDisagreeModalOpen, setIsDisagreeModalOpen] = useState(false);

  const [isEnterModalOpen, setIsEnterModalOpen] = useState(false);
  
  const navigate = useNavigate();
  
  const onCopy = useCallback(() => {
    toast.success('Copied to clipboard', {
      position: 'top-right'
    });
  }, []);
  
  const closeModal = () => {
    setIsAgreeModalOpen(false);
    setIsDisagreeModalOpen(false);

    setIsEnterModalOpen(false);
  };

  const handleNavigateTake = () => {
    props.navigateTake && props.navigateTake();
  };

  const openAgreeModal = (e) => {
    e.stopPropagation();

    if (!isAuthenticated) {
      navigate('/login');
    } 
    else {
      setIsAgreeModalOpen(true);
    }
  };

  const openDisagreeModal = (e) => {
    e.stopPropagation();

    if (!isAuthenticated) {
      navigate('/login');
    } 
    else {
      setIsDisagreeModalOpen(true);
    }
  };

  const openEnterModal = (e) => {
    e.stopPropagation();

    if (!isAuthenticated) {
      navigate('/login');
    } 
    else {
      setIsEnterModalOpen(true);
    }
  };

  useEffect(() => {
    ReactGA.event({
      category: 'everytime take appears in feed',
      action: 'take_cell_impression_web'
    });
  }, []);

  return (
    <div className="takefeed-container">
      <div className="take-header">
        <div className="user-avatar-container">
          <Link to={`/user/${props.take.authorOBJ.id}`} className="link-to-profile">
          <img src={props.take.authorOBJ.media.length ? props.take.authorOBJ.media[0].link : defaultAvatar} width="40" height="40" alt="avatar"></img>
          </Link>
          <p className="username-and-take">@{props.take.authorOBJ.username}: {props.take.the_take}</p>
          <Link to={`/takes/${props.take.id}`} className="link-to-take">
            <span title={getFormattedDate(props.take.createdOn)}></span>
          </Link>
        </div>
        <CopyToClipboard onCopy={onCopy} text={copyUrl}>
          <SendIcon className="share-icon"/>   
        </CopyToClipboard> 
      </div>
      <div onClick={props.navigateTake ? handleNavigateTake : null}>
      <div className="agree-disagree-outer-container">
        <div className="agree-disagree-container">
          <div className="progress-bar-container">
            <progress id="progress-bar" value={(agreedTotal / totalUsersInTake * 100).toFixed()} max={100}>
            </progress>
            <div className="progress-text-left">{(agreedTotal / totalUsersInTake * 100).toFixed()}%</div>
            <div className="progress-text-right">{100-(agreedTotal / totalUsersInTake * 100).toFixed()}%</div>
          </div>
        </div>
      </div>
      <div className="take-info-container">
        <div className="take-info-one">
          <p className="take-info-text"> {totalUsersInTake}</p>
          <p className="take-label">Fans</p>
        </div>
        <div className="take-info-two">
          <p className="take-info-text">{(props.take.takes_points / 1000).toFixed(1)}k</p>
          <p className="take-label">Entry</p>
        </div>
        <div className="take-info-three">
          <p className="take-info-text"> {(props.take.takes_points * totalUsersInTake / 1000).toFixed(1)}k</p>
          <p className="take-label">Pot</p>
        </div>
      </div>
      <div className="enter-and-countdown-container">
        {props.take.participated ?
          <div className="take-agree-disagree-button">
            <div className='participated-button'>Enter</div>
            <div className='countdown'>
              <CountdownIcon className='countdown-icon'></CountdownIcon>
              <Countdown date={props.take.elapsedOn} className="countdown-container">
                {props.take.participated && props.take.isSettled ?
                  props.take.userDidWin ? 
                  <span>You won 🤑</span>
                  :
                  <span>You lost 😭</span>
                  :
                  <span>Expired</span>
                }
              </Countdown>
            </div>
          </div>
          :
          <div className="take-agree-disagree-button">
            <button onClick={openEnterModal}>Enter</button>
            <div className='countdown'>
              <CountdownIcon className='countdown-icon'></CountdownIcon>
              <Countdown date={props.take.elapsedOn} className="countdown-container">
                {props.take.participated && props.take.isSettled ?
                  props.take.userDidWin ? 
                  <span>You won 🤑</span>
                  :
                  <span>You lost 😭</span>
                  :
                  <span>Expired</span>
                }
              </Countdown>
            </div>
          </div>
        }
      </div>
      </div>

      <ReactModal
        isOpen={isEnterModalOpen}
        onRequestClose={() => setIsEnterModalOpen(false)}
        className='confirm-agree-modal'
        overlayClassName='confirm-agree-overlay'
        style={modalStyles}
      >
        {
          isAuthenticated ?
          <EnterTake
            takeId={props.take.id}
            the_take={props.take.the_take}
            userId={user.id}
            token={token}
            spendPoints={props.take.takes_points}
            authorId={props.take.author_id}
            takesStatus={1}
            onClose={closeModal}
            currentPayout={props.take.usersCurrentPayout}
            agreedTotal={agreedTotal}
            disagreedTotal={disagreedTotal}
          />
          : null
        }
        
      </ReactModal>
    </div>
  );
}

export default TakeFeed;