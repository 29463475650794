import React from "react";
import './styles.scss';
import { useNavigate } from "react-router-dom";

const LoginHeader = (props) => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    if (props.postId) {
      localStorage.setItem('lastViewedPost', props.postId);
      localStorage.removeItem('lastViewedTake');
    }
    if (props.takeId) {
      localStorage.setItem('lastViewedTake', props.takeId);
      localStorage.removeItem('lastViewedPost');
    }
    navigate('/login');
  }

  return (
    <div className="login-header-container">
      <button 
        className="header-login-button"
        onClick={navigateToLogin}
      >
        Login / Sign Up
      </button>
    </div>
  );
};

export default LoginHeader;