import React, { useEffect, useState } from "react";
import './styles.scss';
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from "react-redux";
import UserPosts from "../userPosts";
import UserTakes from "../userTakes";
import { followUserAPI, getUserProfile, unfollowUserAPI } from "../../services/apiService";
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import ReactModal from "react-modal";
import UserFollowers from "../userFollowers";
import UserFollowing from "../userFollowing";
import toast from "react-hot-toast";
import { calculateWinRate, modalStyles } from "../../utils/miscUtils";
import { ReactComponent as BackArrow } from "../../assets/svg/backArrowIcon.svg";

const UserProfile = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, user, token } = useSelector(state => state.auth);
  const [userProfileData, setUserProfileData] = useState("");
  const [isFollowersOpen, setIsFollowersOpen] = useState(false);
  const [isFollowingOpen, setIsFollowingOpen] = useState(false);
  const [userProfileError, setUserProfileError] = useState(null);
  
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      setIsFollowersOpen(false);
      setIsFollowingOpen(false);

      getUserProfile(userId, user.id, token)
        .then(response => {
          setUserProfileData(response);
        })
        .catch(error => {
          setUserProfileError(error);
          console.error(error);
        });
    }
  }, [isAuthenticated, userId]);

  const followUser = async () => {
    followUserAPI(user.id, userId, token)
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        toast.error('Failed to follow user', {
          position: 'top-right'
        });
        console.error(error);
      });
  };

  const unfollowUser = async () => {
    unfollowUserAPI(user.id, userId, token)
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        toast.error('Failed to unfollow user', {
          position: 'top-right'
        });
        console.error(error);
      });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="user-profile-page-container">
      <div className="back-button">
        <BackArrow className="back-arrow" onClick={handleGoBack}/>
      </div>
      {userProfileData ?
        <div>
          <div className="user-profile-details-container">
            <img src={userProfileData.user.media.length ? userProfileData.user.media[0].link : defaultAvatar} alt="user avatar"></img>
            <div className="user-profile-name-container">
              <h2 className="user-displayname">{userProfileData.user.displayName}</h2>
              <div className="username-and-follows-you">
                <h4 className="user-username">@{userProfileData.user.username}</h4>
                {userProfileData.followsYou ? 
                  <div className="user-follows-you">
                    <div>
                      <h5>Follows you</h5>
                    </div>
                  </div>
                  : null
                }
              </div>
              <div className="user-profile-follow-container">
                <h5 onClick={() => setIsFollowersOpen(true)}>
                  {userProfileData.followerCount} followers
                </h5>
                <h5 onClick={() => setIsFollowingOpen(true)}>
                  {userProfileData.followingCount} following 
                </h5>
                {userId !== user.id.toString() ?
                  !userProfileData.followingUser ?
                    <button className="follow-unfollow-button" onClick={followUser}>Follow</button>
                    : 
                    <button className="follow-unfollow-button" onClick={unfollowUser}>Unfollow</button> 
                  :
                  null
                }
              </div>
            </div>
          </div>
          <div className="takes-stats-container">
                <div>
                  <h4>Takes Record</h4>
                  <p>{userProfileData.user.wins}-{userProfileData.user.losses}</p>
                </div>
                <div>
                  <h4>Win Rate</h4>
                  <p>{calculateWinRate(userProfileData.user.wins, userProfileData.user.losses)}%</p>
                </div>
                <div>
                  <h4>ROI</h4>
                  <p>{userProfileData.user.roi}%</p>
                </div>
              </div>
          <Tabs>
            <TabList>
              <Tab>Posts</Tab>
              <Tab>Takes</Tab>
            </TabList>

            <TabPanel>
              <UserPosts
                userToRequest={userId}
              /> 
            </TabPanel>
            <TabPanel>
              <UserTakes
                userToRequest={userId}
              />
            </TabPanel>
          </Tabs>

          <ReactModal
            isOpen={isFollowersOpen}
            onRequestClose={() => setIsFollowersOpen(false)}
            className='follow-modal'
            overlayClassName='follow-overlay'
            style={modalStyles}
          >
            <UserFollowers
              userToRequest={userId}
              onClose={() => setIsFollowersOpen(false)}
            />
          </ReactModal>
          <ReactModal
            isOpen={isFollowingOpen}
            onRequestClose={() => setIsFollowingOpen(false)}
            className='follow-modal'
            overlayClassName='follow-overlay'
            style={modalStyles}
          >
            <UserFollowing
              userToRequest={userId}
              onClose={() => setIsFollowingOpen(false)}
            />
          </ReactModal>
        </div>
        :
        null
      }
      {userProfileError && <p>{userProfileError.message}</p>}
    </div>
  );
};

export default UserProfile;