import React, { useEffect } from "react";
import './styles.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BuyPointsCheckoutForm from "../buyPointsCheckoutForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const stripePromise = loadStripe('pk_live_xl91ahJVlEQ58j0nLTw7rxJF00HYgd5YJ9');

const BuyPoints = () => {
  const navigate = useNavigate();
  const { isAuthenticated, token, user } = useSelector(state => state.auth);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated]);

  return (
    <Elements stripe={stripePromise}>
      <div className="buy-points-container">
        <h3>Buy Points</h3>
        <BuyPointsCheckoutForm 
          token={token} 
          userId={user && user.id} 
        />
      </div>
    </Elements>
  );
};

export default BuyPoints;