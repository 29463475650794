import React, { useEffect } from "react";
import './styles.scss';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(state => state.auth);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated]);

  return (
    <div className="page-not-found-container">
      <h3>Page not found. Please check the url.</h3>
    </div>
  )
}

export default NotFound;