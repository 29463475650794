import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import TakeFeed from '../takeFeed';
import PostFeed from '../postFeed';
import { getFeed, incrementPage, resetPage } from '../../reducers/feedSlice';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import ReactGA from 'react-ga4';

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.auth);
  const { posts, status, error, hasMoreData, nextPage } = useSelector(state => state.feed);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } 
    else if (!posts.length) {
      dispatch(getFeed(0));
      dispatch(resetPage());
    }

    ReactGA.event({
      category: 'everytime the feed appears',
      action: 'feed_appear_web'
    });

  }, [isAuthenticated]);
  
  const handleOnBottom = () => {
    if (hasMoreData && status === 'succeeded') {
      dispatch(getFeed(nextPage));
      dispatch(incrementPage());
    }
  };

  return (
    <div className="home-container">          
      <h3>Feed</h3> 
      {posts.map((post) => {
        if (post.linkedObject) {
          const take = post.linkedObject.take;
          return (take && 
            <TakeFeed 
              key={post.id} 
              take={take}
              navigateTake={() => {
                navigate(`/takes/${take.id}`);
              }} 
            />
          );
        } 
        else {
          return (post && 
            <PostFeed 
              key={post.id} 
              post={post}
              navigatePost={() => {
                navigate(`/p/${post.id}`);
              }} 
            />
          ); 
        }
      })}
      {status === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
      <BottomScrollListener onBottom={handleOnBottom} />
    </div>
  );
};

export default Home;

