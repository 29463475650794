import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { feedAPI } from '../services/apiService';
import { cleanData } from '../utils/miscUtils';

const initialState = {
  posts: [],
  nextPage: 1,
  status: null,
  error: null,
  hasMoreData: true
}

export const getFeed = createAsyncThunk('feed/getFeed', async (page, { getState }) => {
  const token = getState().auth.token;
  const userID = getState().auth.user.id;
  const posts = await feedAPI(token, userID, page);
  return posts;
});

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    clearFeed: (state) => {
      state.posts = [];
      state.nextPage = 1;
      state.status = null;
      state.error = null;
      state.hasMoreData = true;
    },
    resetPage: (state) => {
      state.nextPage = 1;
    },
    incrementPage: (state) => {
      state.nextPage += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeed.fulfilled, (state, action) => {
        if (!action.payload.length) {
          state.hasMoreData = false;
        }
        else {
          state.posts = cleanData([...state.posts, ...action.payload]);
        }
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getFeed.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getFeed.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { clearFeed, resetPage, incrementPage } = feedSlice.actions;
export default feedSlice.reducer;