import firebase from 'firebase/compat/app';

const firebaseConfig = {
  apiKey: "AIzaSyCPLymbxva49RL0p6chWMC3zigTdp-o3XI",
  authDomain: "ustadium-new.firebaseapp.com",
  databaseURL: "https://ustadium-new-default-rtdb.firebaseio.com",
  projectId: "ustadium-new",
  storageBucket: "ustadium-new.appspot.com",
  messagingSenderId: "676707788941",
  appId: "1:676707788941:web:d147e495a787774c0d5ac4",
  measurementId: "G-VVB6J3JX7J"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export { firebaseApp };
export default firebase;