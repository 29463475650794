import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { takesAPI } from '../services/apiService';
import { cleanData } from '../utils/miscUtils';

const initialState = {
  takesList: [],
  nextPage: 1,
  status: null,
  error: null,
  hasMoreData: true
}

export const getTakes = createAsyncThunk('takes/getTakes', async (payload, { getState }) => {
  const token = getState().auth.token;
  const userId = getState().auth.user.id;
  const takes = await takesAPI(token, userId, payload.page, payload.filter);
  return takes;
});

const takesSlice = createSlice({
  name: 'takes',
  initialState,
  reducers: {
    clearTakes: (state) => {
      state.takesList = [];
      state.nextPage = 1;
      state.status = null;
      state.error = null;
      state.hasMoreData = true;
    },
    resetPage: (state) => {
      state.nextPage = 1;
    },
    incrementPage: (state) => {
      state.nextPage += 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTakes.fulfilled, (state, action) => {
        if (!action.payload.length) {
          state.hasMoreData = false;
        }
        else {
          state.takesList = cleanData([...state.takesList, ...action.payload]);
        }
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getTakes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getTakes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { clearTakes, resetPage, incrementPage } = takesSlice.actions;
export default takesSlice.reducer;