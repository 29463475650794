import './App.scss';
import Login from './components/login';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home';
import Takes from './components/takes';
import Leaderboard from './components/leaderboard';
import Notifications from './components/notifications';
import Sidebar from './components/sidebar';
import NotFound from './components/notFound';
import IndivPost from './components/indivPost';
import { Toaster } from 'react-hot-toast';
import IndivTake from './components/indivTake';
import UserProfile from './components/userProfile';
import SuggestedUsers from './components/suggestedUsers';
import Shop from './components/shop/shop';
import BuyPoints from './components/buyPoints';
import Redirection from './components/redirection';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<Login/>}/>
          <Route exact path="/shop" element={<Shop/>}/>
          <Route path="*" element={<AuthenticatedLayout/>}/>
        </Routes>
      </Router>
      <Toaster/>
    </div>
  );
}

const AuthenticatedLayout = () => {
  return (
    <div className="authenticated-layout-container">
      <Sidebar />
      <div className="main-content">
        <div className="main-content-container">
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>}/> 
            <Route path="/takes" element={<Takes/>}/>
            <Route path="/leaderboard" element={<Leaderboard/>}/>
            <Route path="/notifications" element={<Notifications/>}/>
            <Route path="/p/:postId" element={<IndivPost/>}/>
            <Route path="/takes/:takeId" element={<IndivTake/>}/>
            <Route path="/user/:userId" element={<UserProfile/>}/>
            <Route path="/buypoints" element={<BuyPoints/>}/>
            <Route path="*" element={<NotFound/>}/>
            <Route path="/events" element={<Redirection/>}/>
          </Routes>
        </div>
      </div>
      <SuggestedUsers/>
    </div>
  );
};

export default App;
