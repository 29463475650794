import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserPosts } from '../services/apiService';
import { cleanData } from '../utils/miscUtils';

const initialState = {
  posts: [],
  status: null,
  error: null,
  hasMoreData: true
}

export const getUserPostsThunk = createAsyncThunk('userPosts/getUserPosts', async (payload, { getState }) => {
  const token = getState().auth.token;
  const userPosts = await getUserPosts(payload.userToRequest, payload.pageNumber, token);

  return userPosts;
});

const userPostsSlice = createSlice({
  name: 'userPosts',
  initialState: initialState,
  reducers: {
    clearUserPosts: (state) => {
      state.posts = [];
      state.status = null;
      state.error = null;
      state.hasMoreData = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPostsThunk.fulfilled, (state, action) => {
        if (!action.payload.length) {
          state.hasMoreData = false;
        }
        else {
          state.posts = cleanData([...state.posts, ...action.payload]);
        }        
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getUserPostsThunk.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getUserPostsThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { clearUserPosts } = userPostsSlice.actions;
export default userPostsSlice.reducer;