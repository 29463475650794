import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getFollowersList } from "../services/apiService";
import { cleanData } from "../utils/miscUtils";

const initialState = {
  followers: [],
  status: null,
  error: null,
  hasMoreData: true
};

export const getFollowersThunk = createAsyncThunk('userFollowers/getFollowers', async (payload, { getState }) => {
  const userId = getState().auth.user.id;
  const token = getState().auth.token;
  const userFollowers = await getFollowersList(userId, payload.pageNumber, payload.userToRequest, token);

  return userFollowers;
});

const userFollowersSlice = createSlice({
  name: 'userFollowers',
  initialState: initialState,
  reducers : {
    clearUserFollowers: (state) => {
      state.followers = [];
      state.status = null;
      state.error = null;
      state.hasMoreData = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFollowersThunk.fulfilled, (state, action) => {
        if (!action.payload.length) {
          state.hasMoreData = false;
        }
        else {
          state.followers = cleanData([...state.followers, ...action.payload]);
        }        
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getFollowersThunk.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getFollowersThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { clearUserFollowers } = userFollowersSlice.actions;
export default userFollowersSlice.reducer;