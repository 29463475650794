import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import './assets/fonts/manrope.ttf';
import firebase from './utils/firebase';
import ReactGA from 'react-ga4';
import 'react-tooltip/dist/react-tooltip.css'
import 'reactjs-popup/dist/index.css';

ReactGA.initialize('G-VVB6J3JX7J');

ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
