import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { indivTakeAPI } from '../services/apiService';

const initialState = {
  take: null,
  status: null,
  error: null
};

export const getIndivTake = createAsyncThunk('indivTake/getIndivTake', async (payload) => {
  const indivTake = await indivTakeAPI(payload.takeId, payload.userId);
  return indivTake;
});

const indivTakeSlice = createSlice({
  name: 'indivTake',
  initialState,
  reducers: {
    clearIndivTake: (state) => {
      state.take = null;
      state.status = null;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIndivTake.fulfilled, (state, action) => {        
        state.take = action.payload;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getIndivTake.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getIndivTake.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { clearIndivTake } = indivTakeSlice.actions;
export default indivTakeSlice.reducer;