import React, { useEffect } from 'react';

const Redirection = () => {
    useEffect(() => {
        window.location.href = 'https://www.ustadiumevents.com';
    }, []);

    return (
        <div className="page-not-found-container">
            <h3>Redirecting to uSTADIUM Events...</h3>
        </div>
    );
};

export default Redirection;
