import React, { useState, useEffect } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux";
import { performLogout } from "../../reducers/authSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import ustadiumLogo from '../../assets/images/ustadium-login-logo.png';
import smallustadiumLogo from '../../assets/images/ustadium-logo-small.png';
import { ReactComponent as HomeIcon } from '../../assets/svg/homeIcon.svg';
import { ReactComponent as TakesIcon } from '../../assets/svg/takesIcon.svg';
import { ReactComponent as LeaderboardIcon } from '../../assets/svg/leaderboardIcon.svg';
import { ReactComponent as MoneyIcon } from '../../assets/svg/moneyIcon.svg';
import { ReactComponent as NotificationIcon } from '../../assets/svg/notificationIcon.svg';
import ReactModal from 'react-modal';
import CreatePost from "../createPost";
import CreateTake from "../createTake";
import { modalStyles } from "../../utils/miscUtils";
import { getUserPoints } from "../../services/apiService";
import { Tooltip } from "react-tooltip";
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isAuthenticated, token } = useSelector(state => state.auth);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 480);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 480 && window.innerWidth <= 768);
  const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
  const [isCreateTakeModalOpen, setIsCreateTakeModalOpen] = useState(false);

  const [userPoints, setUserPoints] = useState({
    cashoutPoints: '',
    promoPoints: ''
  });

  useEffect(() => {
    if (user) {
      fetchPoints()
        .then(response => {
          setUserPoints({
            cashoutPoints: Math.floor(response.cashoutPoints + response.availablePromoPurchase).toLocaleString(),
            promoPoints: Math.floor(response.availablePromoPoints).toLocaleString()
          });
        });
    }
  }, []);

  const fetchPoints = async () => {
    const response = await getUserPoints(user.id);
    return response;
  }

  useEffect(() => {
    ReactModal.setAppElement('body');

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 480);
      setIsMediumScreen(window.innerWidth > 480 && window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen, isMediumScreen]);

  const closeModal = () => {
    setIsCreatePostModalOpen(false);
    setIsCreateTakeModalOpen(false);
  };

  const handleLogout = () => {
    navigate('/login');
    dispatch(performLogout());
  }

  const handleLinkClick = (event) => {
    const eventURL = event.currentTarget.getAttribute("href");
    if (eventURL === location.pathname) {
      window.location.reload();
    }
  }

  return (
    isAuthenticated ? 
    <div className="sidebar-container">
      <img src={isSmallScreen ? smallustadiumLogo : isMediumScreen ? smallustadiumLogo : ustadiumLogo} alt="ustadium logo" className="sidebar-ustadium-logo"></img>
      <div className="pages-container">
        <div>
          <Link to="/" onClick={handleLinkClick}>
            <HomeIcon className="svg-icon"/>
            Home
          </Link>
        </div>
        <div>
          <Link to="/takes" onClick={handleLinkClick}>
            <TakesIcon className="svg-icon"/>
            Takes
          </Link>
        </div>
        <div>
          <Link to="/leaderboard" onClick={handleLinkClick}>
            <LeaderboardIcon className="svg-icon"/>
            Leaderboard
          </Link>
        </div>
        <div>
          <Link to="/notifications" onClick={handleLinkClick}>
            <NotificationIcon className="svg-icon"/>
            Notifications
          </Link>
        </div>
        <div>
        <Link to="/buypoints" onClick={handleLinkClick}>
          <MoneyIcon className="svg-icon"/>
            Buy Points
          </Link>
        </div>
      </div>
      {!isSmallScreen && !isMediumScreen ? <div className="create-buttons-container">
        <button onClick={() => setIsCreatePostModalOpen(true)}>Post</button>
        <button onClick={() => setIsCreateTakeModalOpen(true)}>Take</button>
      </div> : null}
      { isSmallScreen || isMediumScreen ?
        <div>
          <AddCircleIcon fontSize="large" id="create-new-tooltip" style={{ cursor: "pointer" }}/> 
          <Tooltip anchorSelect="#create-new-tooltip" clickable={true} openOnClick={true}>
            <div className="create-new-tooltip-container">
              <button onClick={() => setIsCreatePostModalOpen(true)}>Post</button>
              <button onClick={() => setIsCreateTakeModalOpen(true)}>Take</button>
            </div>
          </Tooltip>
        </div>  
      : null} 
      <div className="user-profile-container">
        <div className="user-profile-inner-container">
          <img
            id="avatar-tooltip"
            src={user ? (user.media.length ? user.media[0].link : defaultAvatar) : null}
            alt="user avatar"
            style={isSmallScreen || isMediumScreen ? { cursor: "pointer" } : null}
          >
          </img>
          {isSmallScreen || isMediumScreen ? 
            <Tooltip anchorSelect="#avatar-tooltip" clickable={true} openOnClick={true}>
              <div className="avatar-tooltip-container">
                {user ?
                  <Link to={`/user/${user.id}`} className="link-to-profile">
                    <div className="username-tag">@{user.username}</div>
                  </Link> : null
                }
                <div className="user-points">CASH POINTS: {userPoints.cashoutPoints}</div>
                <div className="user-points">PROMO POINTS: {userPoints.promoPoints}</div>
                <button onClick={handleLogout}>Log Out</button>
              </div>
            </Tooltip> : null
          }
          {user && !isSmallScreen && !isMediumScreen ?
            <Link to={`/user/${user.id}`} className="link-to-profile">
              <div className="username-tag">@{user.username}</div>
            </Link>
            :
            null
          }
          {!isSmallScreen && !isMediumScreen ? <div className="user-points">CASH POINTS: {userPoints.cashoutPoints}</div> : null} 
          {!isSmallScreen && !isMediumScreen ? <div className="user-points">PROMO POINTS: {userPoints.promoPoints}</div> : null}
          {!isSmallScreen && !isMediumScreen ? <button className="logout-button" onClick={handleLogout}>LOG OUT</button> : null}
        </div>
      </div>
      <ReactModal
        isOpen={isCreatePostModalOpen}
        onRequestClose={() => setIsCreatePostModalOpen(false)}
        contentLabel="Create Post Modal"
        className="create-post-modal"
        overlayClassName="create-post-modal-overlay"
        style={modalStyles}
      >
        <CreatePost
          userId={user.id}
          token={token}
          user={user}
          onClose={closeModal}
        />
      </ReactModal>
      <ReactModal
        isOpen={isCreateTakeModalOpen}
        onRequestClose={() => setIsCreateTakeModalOpen(false)}
        contentLabel="Create Take Modal"
        className="create-take-modal"
        overlayClassName="create-take-modal-overlay"
        style={modalStyles}
      >
        <CreateTake
          userId={user.id}
          token={token}
          user={user}
          onClose={closeModal}
        />
      </ReactModal>
    </div>
    : null
  )
}

export default Sidebar;