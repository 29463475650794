import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import './styles.scss';
import { clearLeaderboard, getLeaderboard, incrementPage, resetPage } from "../../reducers/leaderboardSlice";
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import { useBottomScrollListener } from "react-bottom-scroll-listener";

const Leaderboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.auth);
  const { leaderboardList, status, error, hasMoreData, nextPage } = useSelector(state => state.leaderboard);
  const topThreeUsers = leaderboardList.slice(0, 3);
  const remainingUsers = leaderboardList.slice(3);
  const [displayBottomSpinner, setDisplayBottomSpinner] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } 
    else if (!leaderboardList.length) {
      dispatch(getLeaderboard(0));
      dispatch(resetPage());
    }
    return (() => {
      dispatch(clearLeaderboard());
    });
  }, [isAuthenticated]);

  useBottomScrollListener(() => {
    if (hasMoreData && status === 'succeeded') {
      dispatch(getLeaderboard(nextPage));
      dispatch(incrementPage());
      setDisplayBottomSpinner(true);
    }
  });

  return (    
    <div className="leaderboard-container">
      <h3>Leaderboard</h3>
      <div className="leaderboard-top-user-container">
      {topThreeUsers.map((user, index) => {
        return (
          user ?
          <div className={`${index === 0 ? 'first-place' : index === 1 ? 'second-place' : 'third-place'}`} key={index}>
            <span className="leaderboard-ranking">{index + 1}</span>
            <img src={user.user.media.length ? user.user.media[0].link : defaultAvatar} width="40" height="40" alt="avatar"></img>
            <div className="leaderboard-user-info-container">
              <Link to={`/user/${user.user.id}`} className="link-to-profile">
                <p className="leaderboard-username">@{user.user.username}</p>
              </Link>
              <div className="leaderboard-top-info-container">
                <p className="leaderboard-winrate">{user.wins}-{user.losses}</p>
                <p className="leaderboard-total">Total Points: {user.totalPoints}</p>
                <p className="leaderboard-ROI">Takes ROI:</p>
                <p className="leaderboard-user-roi">{user.roi}%</p>
              </div>
            </div>
          </div> : null
        );
      })}
      {status === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
      </div>
      {remainingUsers.map((user, index) => {
        return (
          user ?
          <div className="leaderboard-user-container" key={index}>
            <img src={user.user.media.length ? user.user.media[0].link : defaultAvatar} width="40" height="40" alt="avatar"></img>
            <span className="remaining-ranking">{index + 4}</span>
            <div className="leaderboard-user-info-container">
              <Link to={`/user/${user.user.id}`} className="link-to-profile">
                <p> @{user.user.username}</p>
              </Link>
              <div className="leaderboard-user-more-info-container">
                <p>{user.wins}-{user.losses}</p>
                <p>Total Points: {user.totalPoints}</p>
                <p>Takes ROI: {user.roi}%</p>
              </div>
            </div>
          </div> : null
        );
      })}
      {status === 'loading' && displayBottomSpinner && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
    </div>
  );
}

export default Leaderboard;