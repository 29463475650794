
export const getTimeDiff = (timeStr) => {
  const now = new Date();
  const createdAt = new Date(timeStr);
  const diffInSeconds = Math.floor((now - createdAt) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`;
  } 
  else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes}m`;
  } 
  else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours}h`;
  } 
  else if (diffInSeconds < 31536000) {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days}d`;
  }
  else {
    const years = Math.floor(diffInSeconds / 31536000)
    return `${years}y`;
  }
};

export const getFormattedDate = (timeStr) => {
  const createdAt = new Date(timeStr);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return createdAt.toLocaleDateString('en-US', options);
}; 