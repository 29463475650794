import React, { useEffect, useState } from "react";
import './styles.scss';
import emailValidator from 'email-validator';
import { useDispatch, useSelector } from "react-redux";
import { performSignup } from "../../reducers/authSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';
import { ReactComponent as EmailIcon } from '../../assets/svg/emailIcon.svg';
import { ReactComponent as UserIcon } from '../../assets/svg/userIcon.svg';
import { ReactComponent as PasswordIcon } from '../../assets/svg/passwordIcon.svg';
import smallustadiumLogo from '../../assets/images/ustadium-logo-small.png';

const Signup = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    email: '',
    username: '',
    password: '',
    passwordVerify: ''
  });
  const { isAuthenticated } = useSelector(state => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      const lastViewedPost = localStorage.getItem('lastViewedPost');
      const lastViewedTake = localStorage.getItem('lastViewedTake');

      if (lastViewedPost) {
        navigate(`/p/${lastViewedPost}`);
        localStorage.removeItem('lastViewedPost');
      }
      else if (lastViewedTake) {
        navigate(`/takes/${lastViewedTake}`);
        localStorage.removeItem('lastViewedTake');
      }
      else {
        navigate('/home');
      }

      toast.success('Account created', {
        position: 'top-right'
      });
    }
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (emailValidator.validate(userInfo.email) && userInfo.password === userInfo.passwordVerify) {
      const userInfoRequestBody = {
        email: userInfo.email,
        username: userInfo.username,
        password: userInfo.password,
        project: 'abd87',
        feeds: [762, 856, 536, 825],
        keywords: [],
        platform: 'web'
      };
      dispatch(performSignup(userInfoRequestBody));
      setUserInfo({
        email: '',
        username: '',
        password: '',
        passwordVerify: ''
      });
    }
    else if (!emailValidator.validate(userInfo.email)) {
      toast.error('Invalid email', {
        position: 'top-right'
      });
    }
    else if (userInfo.password !== userInfo.passwordVerify) {
      toast.error('Passwords don\'t match', {
        position: 'top-right'
      });
    }
  }

  return (
    <div className="signup-container">
      <CloseIcon className="close-icon" onClick={props.onClose}/>
      <form className="signup-form-container" onSubmit={handleSubmit}>
        <div className="signup-form-inner-container">
          <div className="icon-header">
            <img className="ustadium-icon" alt="ustadium-icon" src={smallustadiumLogo}></img>
            <h1>Sign Up</h1>
          </div>
          <div className="input-icon"><EmailIcon className="input-icon-svg"/>Email</div>
          <input
            type="text"
            name="email"
            placeholder="Example@domain.com"
            value={userInfo.email}
            onChange={handleChange}
            required
          />
          <div className="input-icon"><UserIcon className="input-icon-svg"/>Username</div>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={userInfo.username}
            onChange={handleChange}
            required
          />
          <div className="input-icon"><PasswordIcon className="input-icon-svg"/>Password</div>
          <input
            type="password"
            name="password"
            placeholder="Enter Password"
            value={userInfo.password}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="passwordVerify"
            placeholder="Re-enter Password"
            value={userInfo.passwordVerify}
            onChange={handleChange}
            required
          />
          <div className="signup-button-container">
            <button className="signup-button" type="submit">Sign Up</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Signup;