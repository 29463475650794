import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserTakes } from '../services/apiService';
import { cleanData } from '../utils/miscUtils';

const initialState = {
  takes: [],
  status: null,
  error: null,
  hasMoreData: true
}

export const getUserTakesThunk = createAsyncThunk('userTakes/getUserTakes', async (payload, { getState }) => {
  const token = getState().auth.token;
  const userId = getState().auth.user.id;
  const userTakes = await getUserTakes(token, userId, payload.pageNumber, payload.userToRequest);
  return userTakes;
});

const userTakesSlice = createSlice({
  name: 'userTakes',
  initialState: initialState,
  reducers: {
    clearUserTakes: (state) => {
      state.takes = [];
      state.status = null;
      state.error = null;
      state.hasMoreData = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTakesThunk.fulfilled, (state, action) => {
        if (!action.payload.length) {
          state.hasMoreData = false;
        }
        else {
          state.takes = cleanData([...state.takes, ...action.payload]);
        }     
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(getUserTakesThunk.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getUserTakesThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const { clearUserTakes } = userTakesSlice.actions;
export default userTakesSlice.reducer;