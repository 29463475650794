import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import './styles.scss';
import { getNotifications, incrementPage, resetPage } from "../../reducers/notificationsSlice";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import {getTimeDiff, getFormattedDate} from '../../utils/timeUtils';
import defaultAvatar from '../../assets/images/defaultAvatar.png';

const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.auth);
  const { notificationsList, status, error, hasMoreData, nextPage } = useSelector(state => state.notifications);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } 
    else if (!notificationsList.length) {
      dispatch(getNotifications(0));
      dispatch(resetPage());
    }
  }, [isAuthenticated]);

  useBottomScrollListener(() => {
    if (hasMoreData && status === 'succeeded') {
      dispatch(getNotifications(nextPage));
      dispatch(incrementPage());
    }
  });

  const handleNotificationClick = (notification) => {
    if (notification.objectType === 'post') {
      navigate(`/p/${notification.object.id}`);
    }
    else if (notification.objectType === 'takesannouncement') {
      navigate(`/takes/${notification.object.id}`);
    }
  };

  return (
    <div className="notifications-container">
      <h3>Notifications</h3>
      {notificationsList.map(notification => {
        return (
          notification ?
          <div className="notification-indiv-container" key={notification.id} onClick={() => handleNotificationClick(notification)} style={notification.object.id !== 0 ? {cursor: 'pointer'} : null}>
            <img src={notification.author.media.length ? notification.author.media[0].link : defaultAvatar} width="40" height="40" alt="avatar"></img>
            <div className="notification-content">
              <h4>{notification.title}&nbsp;&nbsp;·&nbsp;&nbsp; 
                <span title={getFormattedDate(notification.createdAt)}>
                  {getTimeDiff(notification.createdAt)}
                </span>
              </h4>
              <p>{notification.text}</p>
            </div>
          </div>
          : null
        );
      })}
      {status === 'loading' && <div className='spinner-container'><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}
      {error && <p>{error}</p>}
    </div>
  );
}

export default Notifications;