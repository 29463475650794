export const cleanData = (data) => {
  return Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
};

export const truncateText = (text) => {
  if (text.length > 25) {
    return `${text.slice(0, 26)}...`
  }
  return text;
};

export const getDimensions = (file) => {
  return new Promise((resolve, reject) => {
    if (file.type.includes('image')) {
      const img = new Image();

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        resolve({ width, height });
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = URL.createObjectURL(file);
    }
    else {
      const video = document.createElement('video');

      video.onloadedmetadata = () => {
        const width = video.videoWidth;
        const height = video.videoHeight;
        resolve({ width, height });
      };
      video.onerror = (error) => {
        reject(error);
      };
      video.src = URL.createObjectURL(file);
    }
  });
};

export const calculateWinRate = (wins, losses) => {
  const value = wins / (wins + losses) * 100;
  return value.toFixed();
};

export const getImageGalleryData = (media) => {
  const imageData = [];

  media.forEach(image => {
    imageData.push({
      original: image.link    
    })
  });
  return imageData;
}

export const modalStyles = {
  content: {
    maxHeight: '100%',
    overflowY: 'auto'
  }
};

