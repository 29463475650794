import React, { useState } from "react";
import './styles.scss';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firebaseApp } from "../../utils/firebase";
import { createPost } from "../../services/apiService";
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import { ReactComponent as ImageUploadIcon } from '../../assets/svg/imageUploadIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/closeIcon.svg';
import { useNavigate } from "react-router-dom";
import { getDimensions } from "../../utils/miscUtils";
import toast from "react-hot-toast";

const storage = getStorage(firebaseApp);

const CreatePost = (props) => {
  const [newPost, setNewPost] = useState({
    postContent: '', 
    media: []
  });
  const [selectedFileName, setSelectedFileName] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setNewPost({...newPost, postContent: event.target.value});
  }

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const { width, height } = await getDimensions(file);
      const mediaURL = await uploadFirebase(file);
      setSelectedFileName(file.name);

      if (mediaURL) {
        const mediaObject = {
          kind: file.type.includes('image') ? 'Photo' : 'Video',
          name: file.name,
          link: mediaURL,
          displayType: 'original',
          width: width,
          height: height
        };
        setNewPost({
          ...newPost,
          media: [...newPost.media, mediaObject]
        });
      }
    }
  }

  const uploadFirebase = async (file) => {
    try {
      const storageRef = ref(storage, `media/${props.userId}/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = getDownloadURL(storageRef);
      return downloadURL;
    }
    catch (error) {
      toast.error('Error uploading media', {
        position: 'top-right'
      });
      console.error('Error uploading media', error);
      return null;
    }
  }

  const handleSubmitPost = async (event) => {
    event.preventDefault();

    const postRequestBody = {
      title: newPost.postContent,
      media: newPost.media,
      authorObject_id: props.userId,
      authorType: 'user'
    };

    try {
      await createPost(props.token, postRequestBody);
      setNewPost({ postContent: '', media: [] });
      
      navigate('/');
      window.location.reload();
    }
    catch (error) {
      toast.error('Failed to create post', {
        position: 'top-right'
      });
      console.error(error);
    }
  }

  return (
    <div className="create-post-container">
      <CloseIcon className="close-icon" onClick={props.onClose}/>
      <h2>Create a new post</h2>
      <hr></hr>
      <div className="user-avatar-container">
        <img className="user-avatar" src={props.user ? (props.user.media.length ? props.user.media[0].link : defaultAvatar) : null} alt="user avatar"></img>
        <h3 className="user-name">{props.user ? props.user.username : null}</h3>
      </div>
      <form className="create-post-form" onSubmit={handleSubmitPost}>
        <textarea
          className="create-post-textarea"
          name="postContent"
          placeholder="What is on your mind?"
          value={newPost.postContent}
          onChange={handleChange}
          required
          autoFocus
        />
        <div className="upload-file-container">
          <label className="upload-file-button">
            <ImageUploadIcon className="upload-file-icon"/>
            <input type="file" accept="image/*, video/*" onChange={handleFileUpload}/>
          </label>
          {selectedFileName && <p className="upload-file-name">{selectedFileName}</p>}
        </div>
        <button className="create-post-button" type="submit">Post</button>
      </form>
    </div>
  );
}

export default CreatePost;